import React from 'react';
import Loading from './Loading';

import SongItem from './SongItem';

/**
 * Song Queue used in User Area
 * 
 * @param {*} props 
 * @returns 
 */
export default function SongQueue(props) {
    let { songList } = props

    return (
        songList?.length ? (
        <div>
            <div className="mx-24 text-mainOrangeDark">
                <h5>Aktueller Titel</h5>
                <SongItem
                    key={"key_songItem_" + songList[0].title}
                    isCurrent={true}
                    song={songList[0]}
                    numberinQueue={1}
                />
            </div>
            <div className="mx-16 my-5 border-mainOrangeDark border-dashed border-t-2"></div>
            <div className="mx-24 text-borderGrey">
                <h5>Warteschlange</h5>
                {
                    songList.map((song, index) => {
                        return index !== 0 &&
                        (
                            <SongItem
                                key={"key_songItem_" + index}
                                isCurrent={false}
                                song={song}
                                numberinQueue={index + 1}
                            />
                        );
                    })
                }
            </div>
        </div>)
        : <Loading></Loading>
    );
}