import React from 'react';

import SongItem from './SongItem';

/**
 * Song List used in Admin Dashbaord
 * 
 * @param {*} props 
 * @returns 
 */
export default function SongList(props) {
    const { songList, makeTrend, deleteSong } = props

    return (
        <div>
            <div className="mx-24 text-borderGrey">
                {
                    songList.map((song, index) =>
                        <SongItem
                            key={index}
                            isCurrent={false}
                            song={song}
                            disableTime={true}
                            makeTrend={() => {makeTrend(song)}}
                            deleteSong={() => {deleteSong(song)}}
                        />
                    )
                }
            </div>
        </div>
    );
}