import React from 'react';

import PauseIcon from './../icons/icon_pause.svg';
import PlayIcon from './../icons/icon_play.svg';
import BackIcon from './../icons/icon_back.svg';
import ForwardIcon from './../icons/icon_forward.svg';
import Loading from './Loading';

export default function PlayMenu(props) {
    const { className, onForward, onVolumeChange, toggle, playing, loaded, volume} = props;

    return (
        <div className="flex flex-1 flex-col items-center">
            {/*TODO: classname außerhalb*/}
            <div className={className + " flex items-center"}>
                <button 
                    className="mx-1 relative w-10 h-10 bg-borderGrey rounded-full flex justify-center items-center text-center p-3 text-backgroundWhite"
                    style={{visibility: 'hidden'}}
                >
                    <img src={BackIcon}  alt="Back" />
                </button>
                <button 
                    className="mx-1 relative w-14 h-14 bg-gradient-to-r from-mainOrangeDark to-mainOrangeLight rounded-full flex justify-center items-center text-center p-3 text-backgroundWhite"
                    onClick={toggle}
                    disabled={!loaded}
                >   
                    {loaded ?
                        <img className={playing ? 'p-1.5' : 'p-1 pl-2'} src={!playing ? PlayIcon : PauseIcon} alt="Pause/Play" />
                        :
                        <Loading />
                    }
                </button>
                <button 
                    className="mx-1 relative w-10 h-10 bg-borderGrey rounded-full flex justify-center items-center text-center p-3 text-backgroundWhite"
                    style={{visibility: 'hidden'}}
                    onClick={onForward}
                >
                    <img src={ForwardIcon}  alt="Forward" />
                </button>
            </div>
            <input className='mt-8'  onChange={onVolumeChange} type="range" min={0} max={1} step={0.01} value={volume}></input>
            
        </div>
    );
};