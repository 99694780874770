import React, { useEffect } from 'react';
import UserList from '../components/UserList.jsx';

import { useRealmApp } from "../RealmApp";
import AdminNav from '../components/nav/AdminNav.jsx';

/** Shows the Admins a dashboard to manage the Users and Songs */
export default function AdminDashboard() {
    const app = useRealmApp();
    const {
        getAllUsers,
        activateUser,
        deactivateUser
    } = app.currentUser.functions;

    const [users, setUsers] = React.useState([]);

    const [refetchTrigger, setRefetchTrigger] = React.useState(false);

    useEffect(() => {
        async function fetchData() {
            let _users = await getAllUsers();
            setUsers(_users);
        }
        fetchData();
    }, [refetchTrigger])

    const handleActivateUser = (userId, packages) => {
        activateUser(userId, packages).then(() => {
            setRefetchTrigger(!refetchTrigger);
        });
    }

    const handleDeactivateUser = (userId) => {
        deactivateUser(userId).then(() => {
            setRefetchTrigger(!refetchTrigger);
        });
    }

    return (
        <div className="bg-backgroundGrey h-screen flex flex-col">
            <AdminNav
                active={0}
            />
            <div className="flex mt-10 items-center justify-center">
                <h1 className="text-center text-3xl">Kunden-Übersicht</h1>
            </div>
            <div className="flex mt-5 items-center">
                <div className="flex-1">
                    <UserList
                        users={users}
                        handleActivateUser={handleActivateUser}
                        handleDeactivateUser={handleDeactivateUser}
                    />
                </div>
            </div>
        </div>
    );
};