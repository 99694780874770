import { useState } from "react";

/** Returns a state that can be toggled for updates with `refetch()`.
 * This is useful for useEffects, simply use the trigger as a dependency.
 * 
 * @example
 * const {trigger, refetch} = useRefetch();
 * useEffect(doSomething, [dep1, refetch]);
 * return <button onClick={refetch}>Reload</button>
 */
export default function useRefetch() {
	const [trigger, setTrigger] = useState(false);

	const refetch = () => {
		setTrigger(!trigger);
	}

	return {
		trigger,
		refetch
	}
}