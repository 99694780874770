import React, { useEffect } from 'react';
import Button from '../../components/Button.jsx';
import UploadIcon from "../../icons/icon_upload.svg";
import { useHistory, useParams } from "react-router-dom";
import { useRealmApp } from "../../RealmApp";
import SongList from '../../components/SongList.jsx';
import Modal from '../../components/Modal.jsx';
import GenreField from '../../components/GenreField.jsx';
import parseMetadata from '../../utils/metadataParser.js';
import AdminNav from '../../components/nav/AdminNav.jsx';
import GenreList from '../../components/GenreList.jsx';

/** Shows the Admins a dashboard to manage the Users and Songs */
export default function SongsOverview() {
    const app = useRealmApp();
    const {
        uploadSong,
        getAllSongsInfo,
        setTrendSond,
        getAllAvailableGenres,
        deleteSong,
    } = app.currentUser.functions;

    const [songs, setSongs] = React.useState([]);
    const [availableGenres, setAvailableGenres] = React.useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);

    const [refetchTrigger, setRefetchTrigger] = React.useState(false);
    const inputFile = React.useRef(null);
    const { genre } = useParams();
    const initUploadForm = {
        title: '',
        artist: '',
        genres: [

        ]
    };

    const [uploadForm, setUploadForm] = React.useState(initUploadForm);

    useEffect(() => {
        async function fetchData() {
            let _songs = await getAllSongsInfo();
            setSongs(_songs.filter(_s => _s.genres.includes(genre)));
            let _genres = await getAllAvailableGenres();
            setAvailableGenres(_genres);
        }
        fetchData();
    }, [refetchTrigger, genre])

    const setGenres = (genres) => {
        console.log(genres);

        let tempUploadForm = {
            ...uploadForm
        };

        tempUploadForm.genres = genres;

        setUploadForm(tempUploadForm);

    }

    const onSongSelected = async (e) => {
        const currentFileToUpload = e.target.files[0];

        const metadata = await parseMetadata(currentFileToUpload);

        console.debug("Parsed Metadata: ", metadata)

        setUploadForm({
            ...uploadForm,
            title: metadata.title,
            artist: metadata.artist,
            genres: metadata.genres,
        });
    }

    const onSubmitUploadSongModal = async () => {
        let currentFileToUpload = inputFile.current.files[0];

        const reader = new FileReader();
        reader.onload = async function (evt) {
            const contents = evt.target.result;

            setUploading(true);

            //TODO:
            await uploadSong({ ...uploadForm, file: contents });
            setShowModal(false);
            setUploading(false);
            setUploadForm(initUploadForm)
            setRefetchTrigger(!refetchTrigger);
        };
        reader.readAsDataURL(currentFileToUpload);


    }

    const handleInputChange = (event) => {
        let tempForm = { ...uploadForm };

        const target = event.target;
        console.log("handleInputChange> tempForm:", tempForm, " target.id: ", target.id);
        switch (target.id) {
            case "inputTitle":
                tempForm.title = target.value;
                setUploadForm(tempForm);
                break;
            case "inputArtist":
                tempForm.artist = target.value;
                setUploadForm(tempForm);
                break;
            default:
                break;
        }

    }

    const tagsValid = uploadForm.title !== "" && uploadForm.artist !== "" && !!uploadForm.genres?.length;

    const makeTrend = (song) => {
        setTrendSond(song._id, !song.isTrend);
        setRefetchTrigger(!refetchTrigger);
    }

    const handleDeleteSong = (song) => {
        deleteSong(song._id);
        setRefetchTrigger(!refetchTrigger);
    }

    return (
        <div className="bg-backgroundGrey h-screen flex flex-col">
            <AdminNav
                active={1}
            />
            <div className="mx-24 flex mt-5 justify-between items-center">
                <div className="justify-start w-1/3">

                </div>
                <div className="flex-grow-0 justify-center w-1/3">
                    <h1 className="text-center text-3xl">Song-Verwaltung {genre !== ":genre" && "für " + genre.toUpperCase()}</h1>
                </div>
                <div className="flex justify-end w-1/3">
                    <Button buttonText="Song hochladen" buttonImage={UploadIcon} onClick={() => setShowModal(true)} />
                </div>
                <Modal
                    loading={uploading}
                    title="Song hochladen"
                    showModal={showModal}
                    setShowModal={setShowModal}
                    onSubmit={onSubmitUploadSongModal}
                    tagsValid={tagsValid}
                >
                    <div className="my-10 mx-4 flex flex-col">
                        <input type='file' id='file' accept="audio/*" ref={inputFile} onChange={onSongSelected} />
                        <input className="text-foregroundBlack border border-borderGrey rounded-xl p-2 my-2" type="text" id="inputTitle" placeholder="Titel" value={uploadForm.title} onChange={handleInputChange} />
                        <input className="text-foregroundBlack border border-borderGrey rounded-xl p-2 my-2" type="text" id="inputArtist" placeholder="Künstler" value={uploadForm.artist} onChange={handleInputChange} />
                        <div className='my-2'>
                            <GenreField genres={uploadForm.genres} setGenres={setGenres}></GenreField>
                        </div>
                    </div>
                </Modal>
            </div>


            <div className="mx-20  flex justify-center">


            </div>
            {
                genre === ":genre" ? (
                    <div  className="flex mt-5 items-center">
                        <div className="flex-1">
                            <GenreList
                                genreList={availableGenres}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="flex mt-5 items-center">
                        <div className="flex-1">
                            <SongList
                                songList={songs}
                                makeTrend={makeTrend}
                                deleteSong={handleDeleteSong}
                            />
                        </div>
                    </div>
                )
            }

        </div>
    );
};