export default function RadioInput({ name, items }) {
    return (
        <div className="flex items-center gap-8">
            {
                items?.map(item => {
                    console.log(item);
                    return (
                        <label className="inline-flex items-center">
                            <input type="radio" name={name} className="h-5 w-5 text-red-600" value={item} />
                            <span className="ml-2 text-gray-700">
                                {item}
                            </span>
                        </label>
                    );
                })
            }

        </div>
    )
}