import React from 'react';
import UserItem from './UserItem';

export default function UserList({users, handleActivateUser, handleDeactivateUser}) {

    return (
        
        <div>
            {
                users.filter(user => user.isAdmin).length > 0 &&
                <div className="my-10 mx-24 text-borderGrey">
                    <h5>Administratoren</h5>
                    {
                    
                        users.filter(user => user.isAdmin).map((user) => 
                            <UserItem
                                key={"user_" + user._id}
                                user={user}
                            />
                        )
                        
                    }
                </div>
            }
            {
                users.filter(user => Object.keys(user.packages)?.length && !user.isAdmin).length > 0 &&
                <div className="my-10 mx-24 text-borderGrey">
                    <h5>Aktivierte Kunden</h5>
                    {
                    
                        users.filter(user => Object.keys(user.packages).length && !user.isAdmin).map((user) => 
                            <UserItem
                                key={"user_" + user._id}
                                user={user}
                                handleDeactivateUser={handleDeactivateUser}
                            />
                        )
                        
                    }
                </div>
            }
            {
                users.filter(user => !user.isAdmin && Object.keys(user.packages).length === 0).length > 0 &&
                <div className="my-10 mx-24 text-borderGrey">
                    <h5>Noch nicht aktivierte Kunden</h5>
                    {
                    
                        users.filter(user => !user.isAdmin && Object.keys(user.packages).length === 0).map((user) => 
                            <UserItem
                                key={"user_" + user._id}
                                user={user}
                                handleActivateUser={handleActivateUser}
                            />
                        )
                        
                    }
                </div>
            }
        </div>
    );
};