import React, { useEffect, useState } from 'react'
import { useRealmApp } from '../RealmApp';
import emailjs, { init } from '@emailjs/browser';
import Table from './generic/Table';
import Modal from './Modal';
import Button from './generic/Button';

function SongRequestModal({ modalVisible, setModalVisible, addSongToQueue }) {
	const app = useRealmApp();
	const { getAllSongsInfo } = app.currentUser.functions;

	const [songs, setSongs] = useState([]);
	const [page, setPage] = useState(0);
	const [availablePages, setAvailablePages] = useState(0);
	const [searchBarValue, setSearchBarValue] = useState("");
	const [songsForSongRequest, setSongsForSongRequest] = useState([]);

	const sendMail = () => {
		console.log(searchBarValue);

		var templateParams = {
			from_name: app.currentUser.customData.name,
			message: searchBarValue
		};


		emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE, process.env.REACT_APP_EMAILJS_TEMPLATE, templateParams)
			.then((response) => {
				console.log('SUCCESS!', response.status, response.text);
			}, (error) => {
				console.log('FAILED...', error);
			});
	}

	useEffect(() => {
		async function fetchData() {
			let _songs = await getAllSongsInfo();
			setSongs(_songs);
			setSongsForSongRequest(_songs);
			setAvailablePages(Math.floor(_songs.length / 3));
		}
		fetchData();
	}, []);

	useEffect(() => {
		setAvailablePages(Math.floor(songsForSongRequest.length / 3));
	}, [songsForSongRequest])

	return (
		<Modal
			showModal={modalVisible}
			setShowModal={setModalVisible}
			loading={false}
			title="Songwunsch"
			onSubmit={() => { }}
			tagsValid={true}
			withSubmit={false}
			cancelText="OK"
		>
			<div className="mx-4 flex flex-col">
				<input onChange={searchBarChange} type="text" id="create-account-pseudo" className=" rounded-lg border-transparent flex-1 appearance-none border border-borderGrey w-full py-2 px-4 bg-backgroundWhite text-foregroundBlack placeholder-borderGrey shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder={"Suche..."} />

				{songsForSongRequest.length > 0 ? <Table
					heading={"Verfügbare Songs"}
					data={songsForSongRequest.slice(3 * Math.min(page, availablePages), 3 + (3 * Math.min(page, availablePages)))}
					columns={[{
						title: "Titel",
						render: (obj) => {
							return obj.title
						}
					},{
						title: "Interpret",
						render: (obj) => {
							return obj.artist
						}
					},
					]}
					actions={
						[
							{
								title: "auf Warteschlange",
								onClick: (element) => addSongToQueue(element),
							}
						]
					}
					page={Math.min(page, availablePages)}
					setPage={setPage}
					availablePages={availablePages}
				>

				</Table>
					:
					<div className="mt-10 flex flex-col items-center gap-2" >
						<p>Der gesuchte Song <span className='text-mainOrangeLight'>{searchBarValue}</span> konnte nicht gefunden werden</p>
						<Button onClick={() => sendMail()}>Jetzt melden</Button>
					</div>
				}
			</div>
		</Modal>
	);

	function searchBarChange(e) {
		const value = e.target.value;
		setSearchBarValue(value);
		setSongsForSongRequest([...songs].filter(element => element.title.toLowerCase().includes(value.toLowerCase()) || element.artist.toLowerCase().includes(value.toLowerCase())))
	}
}

export default SongRequestModal