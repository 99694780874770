import React, {Fragment, useEffect} from "react";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ComboBox(props) {
    let { items, selected, setSelected } = props;
    //const [selected, setSelected] = useState(items[0])

    if (typeof(selected) != "object") {
      selected = [];
    }

    return (

        <Listbox value={selected} onChange={setSelected} multiple={true}>
        <div className="relative">
          <Listbox.Button className="text-2xl bg-gradient-to-r from-mainOrangeDark to-mainOrangeLight rounded-xl text-backgroundWhite  px-20 py-2 text-center cursor-default focus:outline-none">
            <div className="">
              <span >{selected ? selected.map((option) =>option).join(' | ') : "Fehler aufgetreten"}</span>
            </div>
            
            <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
  
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute z-10 mt-1 w-full bg-backgroundWhite  max-h-56 rounded-md py-1 text-base  overflow-auto focus:outline-none text-md">
              {items.map((item) => (
                <Listbox.Option
                  key={"key_"+item}
                  className={({ active }) =>
                    classNames(
                      active ? 'text-white bg-indigo-600' : 'text-gray-900',
                      'cursor-default select-none relative py-2 pl-3 pr-9'
                    )
                  }
                  value={item}
                >
                  {/* {({ selected, active }) => (
                    <>
                      <div className="flex items-center">
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>
                          {item}
                        </span>
                      </div>
  
                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-indigo-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )} */}

                      <>
                        <div className="flex items-center">
                          <span className={classNames(selected.includes(item) ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>
                            {item}
                          </span>
                        </div>
    
                        {selected.includes(item) ? (
                          <span
                            className={classNames(
                              true ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    );

}

/*<select className="focus:outline-none appearance-none text-center px-20 py-2 text-2xl text-backgroundWhite bg-gradient-to-r from-mainOrangeDark to-mainOrangeLight rounded-xl">
            {
                items.map((item) => {
                    return (
                        <option className="bg-mainOrangeDark text-backgroundWhite" value={item}>
                            {item}
                        </option>
                    );
                })
            }
        </select>}*/