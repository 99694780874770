const musicMetadata = require('music-metadata-browser');

/**
 * Parses given file to get metadata about it.
 * @param {string} file
 */
async function parseMetadata(file) {
	const parsed = await musicMetadata.parseBlob(file)
	/** Metadata */
	const md = parsed.common;

	const artist = md.artist || md.albumartist || "";
	const title = md.title || "";
	const genres = md.genre || [""];
	const length = parsed.format.duration;

	const metadataOfSong = {
		title,
		artist,
		genres,
		length
	};

	return metadataOfSong;
}

export default parseMetadata;