export default function Skeleton() {
    return (
        <div className="bg-backgroundWhite w-96 mx-auto rounded-2xl shadow-lg">
            <div className="bg-borderGrey h-48 p-3 rounded-t-2xl overflow-hidden animate-pulse">
            </div>
            <div className="h- p-3">
                <div className="grid grid-cols-3 gap-4 mt-2">
                    <div className="h-8 bg-borderGrey rounded animate-pulse">
                    </div>
                    <div className="h-8 bg-borderGrey rounded animate-pulse">
                    </div>
                    <div className="h-8 bg-borderGrey rounded animate-pulse">
                    </div>
                    <div className="h-8 col-span-2 bg-borderGrey rounded animate-pulse">
                    </div>
                    <div className=" h-8 bg-borderGrey rounded animate-pulse">
                    </div>
                    <div className="...">
                    </div>
                    <div className="col-span-2 ...">
                    </div>
                </div>
            </div>
        </div>
    )
}