import React from 'react';
//import { generateColorFromText, pickTextColorBasedOnBgColor } from '../utils/colorUtils';

/**
 * Displays the text in a pill form with calculated colors.  
 * handleClose...Function that runs on click of the x of the pill
 * @param {{title: string, handleClose: Function}} props 
 */
export default function Chip(props) {
    const { title, handleClose, handleClick } = props;

    //let backgroundColor = generateColorFromText(title.toUpperCase());
    //let color = pickTextColorBasedOnBgColor(backgroundColor);

    return (
            <span onClick={handleClick} className={`rounded-xl p-2 mr-2 ${handleClose ? "bg-mainOrangeLight" : "bg-borderGrey"} text-backgroundWhite`} role={handleClick && "button"}>
                {title}
                { handleClose && <span className="p-0 ml-2 btnCloseChip" onClick={handleClose}>&times;</span>}
            </span>
    )
}
