import { useEffect, useState } from "react";
import { useRealmApp } from "../RealmApp";
import useRefetch from "./useRefetch";

/** Hook that returns a state that resolves to wheter or not the current session is active or not */
export default function useActiveSession() {
	const app = useRealmApp();
	const {refetch, trigger} = useRefetch();

	const [activeSession, setActiveSession] = useState();
	useEffect(() => {
		let unmounted = false;

		app.currentUser.functions.sessionControl({ token: app.activeToken }).then(res => {
			if (unmounted) return;
			console.info("useActiveSession: res: ", res)
			setActiveSession(res.access);
		});

		return () => unmounted = true;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [app.activeToken, trigger]);

	return {
		/** @type {boolean?} */
		activeSession,
		refetch,
		trigger
	}
}