import React from "react";
import Loading from "./Loading";

export default function Modal({ withSubmit = true, cancelText = "ABBRECHEN", loading, title, showModal, setShowModal, onSubmit, tagsValid, children }) {
  return (
    <>
      {showModal ? (
        <>
          <div
            className="bg-opacity-70 bg-backgroundGrey justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="my-6 mx-6 bg-backgroundWhite ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-end justify-between p-5  rounded-t ">
                  <h3 className="text-3xl text-foregroundBlack font-semibold ">
                    {title}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                {children}
                {/*footer*/}
                <div className="flex items-center justify-end p-6 rounded-lg">
                  <button
                    className="text-errorDark background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    {cancelText}
                  </button>
                  {
                    withSubmit && (
                    !loading ?
                      (<button
                        className="bg-gradient-to-r from-mainOrangeDark to-mainOrangeLight text-backgroundWhite active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={(e) => onSubmit(e)}
                        disabled={!tagsValid}
                      >
                        SPEICHERN
                      </button>) :
                        <Loading></Loading>
                    )
                  }

                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}