import React, { useEffect } from 'react';

import { useRealmApp } from "../RealmApp";
import handleAuthenticationError from '../utils/authentication-utils';

import Button from '../components/Button';

export default function NotActivated() {
    const app = useRealmApp();

    const [error, setError] = React.useState({});

    const handleLogout = async () => {
        try {
            await app.logOut();
        } catch (err) {
            handleAuthenticationError(err, setError);
        }
    }

    useEffect(() => {
        console.error(error);
    }, [error])

    return (
        <div className='flex h-screen bg-backgroundGrey'>
            <div className="m-auto flex flex-col gap-8">
                <h1 className="text-center text-3xl mt-5">Sie sind noch nicht aktiviert!</h1>
                <div className="flex justify-center">
                    <Button  
                        buttonText="Ausloggen" 
                        onClick={handleLogout}
                    />
                </div>

            </div>
        </div>
    );
};