import React from 'react';
import HighlightedStarIcon from './../icons/icon_star_highlighted.svg';
import StarIcon from './../icons/icon_star.svg';
import DeleteIcon from './../icons/icon_delete.svg'

export default function SongItem(props) {
    let { disableTime, isCurrent, song, numberinQueue, makeTrend, deleteSong } = props;

    return (
        <div className="mt-3 flex items-center">
            <div className="flex text-xl">{numberinQueue}.</div>
            <div className={"ml-5 py-2 text-2xl px-4 flex flex-grow justify-between " + (isCurrent ? "bg-gradient-to-r from-mainOrangeDark to-mainOrangeLight" : "bg-borderGrey") + " rounded-xl"}>
                <span className="text-backgroundWhite">{song.title}<span className={(isCurrent ? "text-secondaryOrange" : "text-backgroundGrey") + " text-sm"}> von {song.artist}</span></span>
                {disableTime || <span className="text-backgroundWhite"></span>}
                <div className='flex justify-center items-center gap-4'>
                    {makeTrend && <span onClick={makeTrend} className='text-backgroundWhite cursor-pointer'><img src={song.isTrend ? HighlightedStarIcon : StarIcon} alt="" /></span>}
                    {deleteSong && <span onClick={deleteSong} className='text-backgroundWhite cursor-pointer'><img  src={DeleteIcon} alt="" /></span>}
                </div>
            </div>
        </div>
    );  
}