import React from 'react';

import { useHistory } from 'react-router-dom';

export default function GenreItem(props) {
    let { genre } = props;
    const history = useHistory();

    return (
        <div className="mt-3 flex items-center">
            <div className={"ml-5 py-2 text-2xl px-4 flex flex-grow justify-between bg-gradient-to-r from-mainOrangeDark to-mainOrangeLight rounded-xl cursor-pointer"} onClick={() => history.push("/songs/" + genre)}>
                <span className="text-backgroundWhite">{genre}</span>
            </div>
        </div>
    );  
}