import React from "react";

import * as Realm from "realm-web";

const RealmAppContext = React.createContext();

export const useRealmApp = () => {
  /** @type {...Realm.App<globalThis.Realm.DefaultFunctionsFactory & globalThis.Realm.BaseFunctionsFactory, SimpleObject$2>, currentUser: Realm.User<globalThis.Realm.DefaultFunctionsFactory & globalThis.Realm.BaseFunctionsFactory, SimpleObject$2, globalThis.Realm.DefaultUserProfileData>, activeToken, logIn: (credentials: any) => Promise<void>, logOut: () => Promise<void>} */
  const app = React.useContext(RealmAppContext);
  if (!app) {
    throw new Error(
      `You must call useRealmApp() inside of a <RealmAppProvider />`
    );
  }
  return app;
};

export const RealmAppProvider = ({ appId, children }) => {
  const [app, setApp] = React.useState(new Realm.App(appId));

  React.useEffect(() => {
    const tmpApp = new Realm.App(appId);
    setApp(tmpApp);

    setActiveToken(tmpApp.storage.get("token"))
  }, [appId]);

  const [currentUser, setCurrentUser] = React.useState(app.currentUser);
  const [activeToken, setActiveToken] = React.useState();

  async function logIn(credentials) {
    const res = await app.logIn(credentials);
    console.log("app.logIn: res:", res)
    console.log("app.logIn: res.functions.handleLogin: ", res.functions.handleLogin)

    let token;
    try {
      token = await res.functions.handleLogin();
      app.currentUser.refreshCustomData()
      console.info("app.logIn: new token: ", token);

      setCurrentUser(app.currentUser);

      setActiveToken(token);
      app.storage.set("token", token);
    } catch (err) {
      console.error(err)
      throw err;
    }
  }

  async function logOut() {
    const tokenPayload = { token: activeToken };

    const { access } = await app.currentUser.functions.sessionControl(tokenPayload)
    if (access) {
      // clear the currently active session in db
      const r = await app.currentUser.functions.deactivateSession(tokenPayload);
      console.log(r);

      console.info("Deactivated this Session");
    }

    await app.currentUser?.logOut();
    // If another user was logged in too, they're now the current user.
    // Otherwise, app.currentUser is null.
    setCurrentUser(null);
    setActiveToken();
  }

  const wrapped = { ...app, currentUser, activeToken, logIn, logOut };

  return (
    <RealmAppContext.Provider value={wrapped}>
      {children}
    </RealmAppContext.Provider>
  );
};
