import React from 'react';

import GenreItem from './GenreItem';

/**
 * Genre List used in Admin Dashbaord
 * 
 * @param {*} props 
 * @returns 
 */
export default function GenreList(props) {
    const { genreList } = props

    return (
        <div>
            <div className="mx-24 text-borderGrey">
                {
                    genreList.map((genre, index) =>
                        <GenreItem
                            key={index}
                            genre={genre}
                        />
                    )
                }
            </div>
        </div>
    );
}