import { useEffect, useRef, useState } from "react";
import { useRealmApp } from "../RealmApp";
import { GetAdBlocksByUserRequest, GetAdByIdRequest, GetAdsByUserRequest, GetSongRequest, SyncRequest } from "../utils/yosoRequest";
import { insertAt } from "../utils/queue";
import useSyncedPlayer from "./useSyncedPlayer";


/**
 * **Hook for Audio playing**
 * @param {string} src Source for {@link Audio}
 * @returns 
 */
export const useAudio = (src, onEnded) => {
  const [audio, setAudio] = useState(new Audio(src));
  const audioRef = useRef();
  audioRef.current = audio;
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);

  // Autoupdate src
  useEffect(() => {
    if (src !== audio.src) changeAudioSource(src);
  }, [src]);

  useEffect(() => {
    playing ? audio.play().catch(err => console.warn("useAudio: playing error")) : audio.pause();
  }, [playing, audio]);

  useEffect(() => {
    console.debug("useAudio: Volume change: ", volume);
    audio.volume = volume;
  }, [volume, audio])

  useEffect(() => {
    console.debug("useAudio: add eventlistener");

    audioRef.current.addEventListener('ended', () => {
      console.info("useAudio: Audio ended");
      //setPlaying(false);
      if (onEnded) onEnded();
    });

    // For dev purposes only
    // setTimeout(() => {
    //   console.warn("Fake end of song!");
    //   if (onEnded) onEnded();
    // }, 20000)

    return () => {
      try {
        audio?.pause();
        audio?.remove();
      } catch (err) {
        console.warn("useAudio:cleanup failed with: ", err)
      }
    };
  }, [audioRef.current]);


  const togglePlayPause = () => setPlaying(!playing);

  /** Changes source to a new one */
  const changeAudioSource = (src) => {
    audio.pause();
    audio.remove();
    const oldVolume = audio.volume;

    const tmpAudio = new Audio(src);
    audio.volume = oldVolume;
    console.debug("useAudio: Volume for new Audio: ", audio.volume);
    setAudio(tmpAudio);
  }

  return {
    playing, togglePlayPause,
    setVolume, volume,
  };
};

/**
 * **Hook to manage an audio player with Queue and cache**
 * 
 * @param {string} genre 
 * **The genre to query for.**
 * eg.: `Rock`
 * 
 * @param {number} queueLength
 * **Length of stored queue**.
 *   
 * The queue holds the sequence of songs.
 * The first {@link songsToCache}-songs will be cached in {@link songCache}, so that they will have a `file`-property
 * 
 * @param {number} songsToCache
 * **The number of songs that should be loaded in advance**.
 *
 * Songs get cached to minimize loading-times.
 * The value must be smaller than {@link queueLength}.
 * 
 * @returns 
 */
export const usePlayer = (queueLength = 4, songsToCache = 2) => {
  const app = useRealmApp();
  const {
    getRandomSongQueue,
    getSong,
    syncPlayer,
    getAdBlocksByUser,
    getAdById,
    clearSongRequests
  } = app.currentUser.functions;

  const { songRequests, refetch } = useSyncedPlayer(10000);

  /** 
   * Holds upcoming songs.
   * @type {[[{_id: object, artist: string, genre: [string], title: string}]]} 
   */
  const [songQueue, setSongQueue] = useState();
  /** As to why Ref is used check out {@link playNext} */
  const songQueueRef = useRef();
  songQueueRef.current = songQueue;

  /** 
   * The current song. Has file-content.
   * @type {[{_id: object, artist: string, genre: [string], title: string, file: string}]}
   **/
  const [currentSong, setCurrentSong] = useState();
  const [genre, setGenre] = useState();

  // const [currentAdBlockQueue, setCurrentAdBlockQueue] = useState([]);
  const currentAdBlockQueueRef = useRef([]);
  const [currentAd, setCurrentAd] = useState();
  const [timers, setTimers] = useState();

  const [playingAd, setPlayingAd] = useState(!!currentAd?.file);

  const audioSrc = playingAd ? currentAd.file : currentSong?.file;
  // console.log("usePlayer: Current Song: ", currentSong?.title);
  // console.log("usePlayer: Current Ad: ", currentAd);
  // console.debug("usePlayer: CurrentAudioSrc: ", audioSrc)
  const { playing, togglePlayPause: _togglePlayPause, setVolume } = useAudio(audioSrc, playNext);

  //#region Heavy Lifting
  //#region SongQueue
  const [loaded, setLoaded] = useState(false);
  const loadRandomSongQueue = () => {
    if (!genre) {
      console.warn(`usePlayer: '${genre}' not valid as genre! Aborting loading of SongQueue`)
      return;
    }

    console.log("usePlayer: Loading Random Song Queue...");
    let unmounted = false;
    const cleanupEffect = () => unmounted = true;

    getRandomSongQueue(queueLength, genre).then(_songs => {
      console.debug(`usePlayer: Loaded SongQueue for Genre=${genre}: `, _songs);
      if (!unmounted) {
        // only gets called when changing the genre...{@link playNext}
        if (playing) {
          _songs = [currentSong, ..._songs];
        }

        setSongQueue(_songs);
        console.log(`usePlayer: Loaded queue with ${_songs.length} songs for Genre=${genre}`);

        syncPlayer(SyncRequest(_songs, genre)).then(async () => {
          console.log("usePlayer: songQueue updated in db", _songs)
        });
      }
    });

    return cleanupEffect;
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadRandomSongQueue, [genre]);

  useEffect(() => {
    console.log("usePlayer: songRequests: ", songRequests);

    if (!songQueue) {
      console.log("usePlayer: songRequests: no Queue")
      return
    }

    if (!songRequests || songRequests.length === 0) {
      console.log("usePlayer: songRequests: no songRequests")
      return
    }

    let tmpSongQueue = [...songQueueRef.current]
    for (const songRequest of songRequests) {
      console.log("usePlayer: songRequests: add: ", songRequest.song)
      // addSongToQueue(songRequest.song)
      tmpSongQueue = insertAt(tmpSongQueue, songRequest.song, firstPossibleQueueIndexForSong())
    }
    console.log("NEW QUEUE: ", tmpSongQueue)
    setSongQueue(tmpSongQueue);

    clearSongRequests();
    pushSyncQueue(tmpSongQueue, genre);
    refetch();
  }, [songRequests])

  function addSongToQueue(song) {
    const songQueue = songQueueRef.current;


    const i = firstPossibleQueueIndexForSong();
    const newSongQueue = [...songQueue.slice(0, i), { ...song }, ...songQueue.slice(i)];
    console.log("usePlayer: addSongToQueue: newQueue: ", newSongQueue);
    setSongQueue(newSongQueue);
  }

  /** Returns index of the first possible index, where a song could be queued
   * i.e. first place after the current that is not an adblock
   */
  function firstPossibleQueueIndexForSong() {
    // an adblock does have a times attribute.
    let i = 1;
    while (i < songQueue.length && songQueue[i].times) {
      i++;
    }
    return i;
  }
  //#endregion
  //#region Cache

  /** 
   * Holds all cached Songs 
   * @type {[[{_id: object, artist: string, genre: [string], title: string, file: string}]]}
   */
  const [songCache, setSongCache] = useState([]);
  /** As to why Ref is used check out {@link playNext} */
  const songCacheRef = useRef();
  songCacheRef.current = songCache;

  const cacheSongs = () => {
    // songPlaying gets handled extra
    if (playingAd) return;
    console.log("usePlayer: Caching Songs...");
    console.debug("usePlayer: Caching Songs...\n", songQueue)
    console.debug("usePlayer: ref-ed queue: ", songQueueRef.current)

    let unmounted = false;
    const cleanupEffect = () => unmounted = true;

    if (!songQueue?.length) {
      console.log("usePlayer: Caching of songs aborted; no Songs in queue");
      return cleanupEffect;
    }

    const promises = [];
    // create getSong-promises
    for (let i = 0; i < Math.min(songsToCache, songQueue.length); i++) {
      if (songCache[i]?._id === songQueue[i]?._id && songCache[i]?.file)
        // don't load songs, that are already cached.
        promises.push(new Promise(res => res(songCache[i])));
      else
        promises.push(
          getSong(GetSongRequest(app.activeToken, songQueue[i]._id)).then(song => song[0])
        );
    }

    // Handle data
    Promise.all(promises).then(loadedSongs => {
      if (unmounted || playingAd) return;
      console.log(`usePlayer: ${loadedSongs?.length}/${songsToCache}/${songQueue.length} songs cached`);
      console.debug("usePlayer: Loaded Songs to cache: ", loadedSongs);

      setSongCache(loadedSongs);
      setLoaded(true);
    });

    return cleanupEffect;
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(cacheSongs, [app.activeToken, songQueue, playingAd])
  const invalidateCache = () => {
    // only keep the current song in cache
    setSongCache([currentSong])
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(invalidateCache, [genre])

  // auto Update current
  useEffect(() => {
    if (songCache[0]?._id !== currentSong?._id) {
      setCurrentSong(songCache[0]);
      console.log("usePlayer: New CurrentSong: ", songCache[0]?.title);
      if (!songCache[0]?.file) setLoaded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [songCache])
  //#endregion
  //#endregion

  //#region Ads
  // Flow:
  // 1 Get AdBlocks
  // 2 create timeouts for each AdBlock
  // 3 When timeout executes queue the adBlock to play after current song
  // 4 When adBlock starts to play notify via `setPlayingAd(true)`
  // 5 After adBlock finishes to play, notify via `setPlayingAd(false)`

  //1
  const [loadedAdBlocks, setLoadedAdBlocks] = useState(false);
  useEffect(() => {
    // to not load Ads as long as the songQueueRef has no value
    if (loadedAdBlocks || !songQueueRef.current) return
    getAdBlocksByUser(GetAdBlocksByUserRequest(app.currentUser.id)).then(_adBlocks => {
      // 2
      createAdTimers(_adBlocks);
      setLoadedAdBlocks(true);
    });

    return () => {
      clearAdTimers()
    };
  }, [songQueueRef.current]);

  // 2
  const createAdTimers = (adBlocks) => {
    const ads = adBlocks.map(adBlock => adBlock.ads).flat();
    // console.log("usePlayer: createAdTimers: Ads: ", ads);

    const tmpTimers = [];
    const now = new Date();
    // console.log("usePlayer: createAdTimers: now.getMinutes: ", now.getMinutes())

    /** oneSecond in milliseconds */
    const oneSecond = 1000;
    /** oneMinute in milliseconds */
    const oneMinute = 60 * oneSecond;

    for (const adBlock of adBlocks) {
      console.log("usePlayer: createAdTimers: times: ", adBlock.times);
      for (const time of adBlock.times.map(Number)) {
        /** In Minutes */
        const deltaTime = time < now.getMinutes() ? 60 - now.getMinutes() + time : time - now.getMinutes();
        // console.log("usePlayer: createAdTimers: deltaTime: ", deltaTime)
        console.debug("usePlayer: createAdTimers: adBlock: ", adBlock);
        // 3
        tmpTimers.push(setTimeout(() => queueAdBlock(adBlock), deltaTime * oneMinute))
      }
    }

    setTimers(tmpTimers);
  }

  //3
  const queueAdBlock = (adBlock) => {
    // insert AdBlock after currentSong...index 0
    const exec = () => {
      const _songQueue = songQueueRef.current;
      // console.debug("queueAdBlock: currentSong: ", currentSong)
      const newSongQueue = [_songQueue[0], { ...adBlock }, ..._songQueue.slice(1)]
      setSongQueue(newSongQueue);
      console.log("queueingAd: ", adBlock)
      console.debug("usePlayer: queueAdBlock: currentAdBlockQueue: ", currentAdBlockQueueRef.current)
      console.debug("usePlayer: queueAdBlock: adBlock: ", adBlock)
      currentAdBlockQueueRef.current = [...currentAdBlockQueueRef.current, { ...adBlock }];
      console.debug("usePlayer: queueAdBlock: currentAdBlockQueue afterwards: ", currentAdBlockQueueRef.current)
    }

    const _songQueue = songQueueRef.current;
    if (!_songQueue) {
      // only do with timeout, if not working otherwise
      setTimeout(exec, 0)
    } else {
      exec();
    }
  }

  // 4
  /** Will play the next ad of the {@link currentAdBlock}, if possible */
  async function playNextAd() {
    async function handleNoAd() {
      console.debug("usePlayer: playNextAd: handleNoAd");
      currentAdBlockQueueRef.current.shift();
      console.log("usePlayer: playNextAd: Next AdBlock is queued: ", currentAdBlockQueueRef.current[0]);
      return await playNextAd();
    }

    let currentAdBlock = currentAdBlockQueueRef.current[0];
    console.debug("usePlayer: playNextAd: currentAdBlock: ", currentAdBlock);
    console.log("usePlayer: playNextAd: ref: ", currentAdBlockQueueRef.current)

    if (!currentAdBlock?.ads?.length) {
      if (currentAdBlockQueueRef.current.length <= 1) {
        if (playingAd) {
          // was playing an ad before
          setPlayingAd(false);
          console.log("usePlayer: playNextAd: setPlayingAd(false)")
        }
        return false;
      } else {
        handleNoAd();
      }
    }

    // console.log("usePlayer: playNextAd: adBlock: ", currentAdBlock);

    // find a ad that has a file
    let ad;
    while ((ad?.file?.length ?? 0) < 200 || (ad?.type === "Fremdwerbung" && app.currentUser.customData.packages.removeForeignMarketing)) {
      if (!currentAdBlock.ads?.length) {
        return await handleNoAd();
      }

      const adId = currentAdBlock.ads.shift()._id.toString();
      ad = await getAdById(GetAdByIdRequest(adId));
      // console.log("usePlayer: playNextAd: ad: ", ad, " length: ", ad?.file?.length);
    }

    console.log("usePlayer: playNextAd: new adBlock: ", currentAdBlock);
    console.log("usePlayer: playNextAd: new ad: ", ad);
    setCurrentAd(ad);

    // console.log("usePlayer: playNextAd: used ad: ", ad);
    if (!playingAd) {
      // was not playing an ad before
      songQueueRef.current?.shift();
      songCacheRef.current?.shift();
      setSongQueue([...songQueueRef.current])
      setSongCache([...songCacheRef.current])
      // console.log("usePlayer: playNextAd: shifted queue & cache")
      setPlayingAd(true)
      console.log("usePlayer: playNextAd: setPlayingAd(true)")
    }

    return true;
  }

  const clearAdTimers = () => {
    for (const timer in timers) {
      clearTimeout(timer);
    }
    setTimers(undefined);
  }
  //#endregion

  //#region Functionality
  function togglePlayPause() {
    _togglePlayPause();
  };

  /**
   * Will play the next ad (if present) or song in queue. If no song is in queue nothing will crash.
   * The handling of loading additional songs into cache afterwards is handled in the various useEffects.
   */
  async function playNext() {
    const playingNextAd = await playNextAd();
    console.log("usePlayer: playNext: playingNextAd: ", playingNextAd);
    if (playingNextAd) return

    // Use refs, because this will be called from another callback, that doesn't have the state.
    const songQueue = songQueueRef.current;
    const songCache = songCacheRef.current;

    let getSongs = 1;
    // if (playingAd) {
    //   // ad was playing before
    //   getSongs = 2
    // }

    const songQueueToAppend = await getRandomSongQueue(getSongs, genre);
    const appendedSongQueue = [...songQueue, ...songQueueToAppend];

    const shiftedQueue = [...appendedSongQueue];
    const shiftedCache = [...songCache];

    console.debug("usePlayer: not shiftedQueue: ", shiftedQueue);
    console.debug("usePlayer: not shiftedCache: ", shiftedCache);

    // remove the song, keep cache and queue in sync
    shiftedQueue?.shift();
    shiftedCache?.shift();
    console.debug("usePlayer: shiftedQueue: ", shiftedQueue);
    console.debug("usePlayer: shiftedCache: ", shiftedCache);


    setSongQueue(shiftedQueue);
    setSongCache(shiftedCache);

    pushSyncQueue(shiftedQueue, genre);
  };

  const changeVolume = (event) => {
    setVolume(event.target.value);
  }
  //#endregion

  return {
    currentSong: playingAd ? currentAdBlockQueueRef.current[0] : currentSong,
    songQueue,
    playing,
    loaded,
    togglePlayPause,
    playNextSong: playNext,
    changeVolume,
    genre,
    setGenre,
    playingAd,
    addSongToQueue
  };

  async function pushSyncQueue(queue, genre) {
    await syncPlayer(SyncRequest(queue, genre))
    console.info("usePlayer: SYNC: ", queue)
  }
}