import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AdminNav from "../components/nav/AdminNav";
import Skeleton from "../components/generic/Skeleton";
import Table from "../components/generic/Table";
import { useRealmApp } from "../RealmApp";
import { GetAdBlocksByUserRequest, GetAdsByUserRequest } from "../utils/yosoRequest";

export default function AdsOverview() {
    const app = useRealmApp();
    const {
        getUser,
        getAdBlocksByUser,
        getAdsByUser,
    } = app.currentUser.functions;

    const { userid } = useParams();

    const [user, setUser] = useState(null);
    const [adBlocks, setAdBlocks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [ads, setAds] = useState([]);

    const history = useHistory();

    useEffect(() => {
        async function fetchData() {
            let _user = await getUser({args: {userId: userid}, token: app.currentUser.token});
            let _ads = await getAdsByUser(GetAdsByUserRequest(userid, ["Fremdwerbung"]));
            let _adBlocks = await getAdBlocksByUser(GetAdBlocksByUserRequest(userid))
            setUser(_user);
            setAds(_ads);
            setAdBlocks(_adBlocks);
            setIsLoading(false);
        }
        fetchData();
    }, [userid])

    const navigateToCreateEditAds = () => {
        history.push("/create-edit-ads/" + userid);
    }

    const navigateToCreateEditAdBlocks = () => {
        history.push("/create-edit-ad-blocks/" + userid);
    }

    return (
        <div className=" h-screen bg-backgroundGrey flex flex-col">
            <AdminNav 
                active={0}
            />
            <div className="flex mt-10 items-center justify-center">
                <h1 className="text-center text-3xl">Marketing für den Kunden <span className="text-mainOrangeDark">{user?.name}</span></h1>
            </div>
            <div className="mt-10">
                {!isLoading ? 
                <Table 
                    heading="Werbeblöcke"
                    data={adBlocks}
                    onButtonClick={navigateToCreateEditAdBlocks}
                    columns={[
                        {
                            title: "Name",
                            render: (obj) => {
                                return obj.title
                            }
                        },
                        {
                            title: "Wann",
                            render: (obj) => {
                                let times = "";
                                obj.times.forEach((element, index) => {
                                    times += element + (index < obj.times.length - 1 ? " | " : "");
                                })
                                return times;
                            }
                        },
                        {
                            title: "Enthaltene Werbung",
                            render: (obj) => {
                                return obj.ads.length
                            }
                        }
                    ]}
                /> : <Skeleton />}
            </div>
            <div  className="mt-10">
            {!isLoading ? <Table 
                    heading="Jingles und Eigenwerbung"
                    data={ads}
                    onButtonClick={navigateToCreateEditAds}
                    columns={[
                        {
                            title: "Titel",
                            render: (obj) => {
                                return obj.title
                            }
                        },
                        {
                            title: "Typ",
                            render: (obj) => {
                                return obj.type
                            }
                        },
                    ]}
                /> : <Skeleton />}
            </div>
        </div>
    )
}