import Button from "./Button";

export default function Table({ heading, columns, data, onButtonClick, actions, page, setPage, availablePages }) {
    console.log("Table: page: ", page)

    return (
        <div className="container mx-auto px-4 sm:px-8 max-w-3xl">
            <div className="py-8">
                <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full">
                    <h2 className="text-2xl leading-tight">
                        {heading}
                    </h2>
                    <div className="text-end">
                        <form className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center">
                            <div className=" relative ">
                                {/*TODO: Invisible */}
                                <input type="text" id="&quot;form-subscribe-Filter" className="invisible rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-backgroundWhite text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Suche..." />
                            </div>
                            {onButtonClick && <Button onClick={onButtonClick}>Hinzufügen</Button>}
                        </form>
                    </div>
                </div>
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                        <table className="min-w-full leading-normal">
                            <thead>
                                <tr>
                                    {
                                        columns?.map((column, index) => {
                                            return (
                                                <th key={index} scope="col" className="px-5 py-3 bg-backgroundWhite  text-gray-800  text-left text-sm uppercase font-normal">
                                                    {column.title}
                                                </th>
                                            )
                                        })
                                    }
                                    <th scope="col" className="px-5 py-3 bg-backgroundWhite  text-gray-800  text-left text-sm uppercase font-normal">
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data?.map((element, elemIndex) => {
                                        return (
                                            <tr key={elemIndex}>
                                                {columns.map((column, index) => {
                                                    return (
                                                        <td key={index} className="px-5 py-5 border-t border-borderGrey bg-backgroundWhite text-sm">
                                                            <p>
                                                                {columns[index]?.render(element)}
                                                            </p>
                                                        </td>
                                                    )
                                                })}
                                                <td className="px-5 py-5 border-t border-borderGrey bg-backgroundWhite text-sm">
                                                    {actions ?
                                                        actions.map((action, index) => {
                                                            return (<Button key={index} onClick={() => { action.onClick(element) }}>{action.title}</Button>)
                                                        })
                                                        :
                                                        <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                                            Edit
                                                        </a>}
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                        {(availablePages) ?
                            <div className="px-5 bg-white py-5 flex flex-col xs:flex-row items-center xs:justify-between">
                                <div className="flex items-center">
                                    <button onClick={() => 0 <= page - 1 && setPage(page - 1)} type="button" className="w-full p-4 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100">
                                        <svg width="9" fill="currentColor" height="8" class="" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z">
                                            </path>
                                        </svg>
                                    </button>
                                    
                                    {
                                          [...Array(availablePages)].map((x, i) =>
                                            <button onClick={() => {setPage(i)}} type="button" className={"w-full px-4 py-2 border-t border-b text-base text-indigo-500 bg-white hover:bg-gray-100 " + (i === page ? "font-bold text-mainOrangeDark " : "")}>
                                                {i + 1}
                                            </button>
                                          )


                                    }
                                    <button onClick={() => availablePages > page + 1 && setPage(page + 1)} type="button" className="w-full p-4 border-t border-b border-r text-base  rounded-r-xl text-gray-600 bg-white hover:bg-gray-100">
                                        <svg width="9" fill="currentColor" height="8" className="" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z">
                                            </path>
                                        </svg>
                                    </button>
                                </div>
                            </div> :
                            <></>}
                    </div>
                </div>
            </div>
        </div>
    )
}