import React from 'react';

import ActivateIcon from './../icons/icon_activate.svg';
import AdsIcon from './../icons/icon_ads.svg';
import ResetIcon from "./../icons/icon_reset.svg";
import Modal from './Modal';
import Package from './Package';

import { useHistory } from 'react-router-dom';

export default function UserItem({user, handleActivateUser, handleDeactivateUser}) {
    const [bgColorStyling, setBgColorStyling] = React.useState("");
    const [showModal, setShowModal] = React.useState(false);
    const [packages, setPackages] = React.useState({
        basic: false,
        songRequest: false,
        removeForeignMarketing: false
    })
    const history = useHistory();

    React.useEffect(() => {
        if (!user.isAdmin && !Object.keys(user.packages).length) {
            setBgColorStyling("bg-borderGrey")
        } else if (Object.keys(user.packages).length && !user.isAdmin) {
            setBgColorStyling("bg-gradient-to-r from-mainOrangeDark to-mainOrangeLight");
        } else if (user.isAdmin) {
            setBgColorStyling("bg-gradient-to-r from-errorDark to-errorLight");
        }
    }, [user]);

    const openActivationModel = () => {
        setShowModal(true);
        //handleActivateUser(user._id);
    }

    const openAdsModel = () => {
        history.push("/ads/" + user._id)
    }

    const changePackage = (e) => {
        let tempPackages = {};
        if (e.target.name === "basic" && !e.target.checked) {
            setPackages({
                basic: false,
                songRequest: false,
                removeForeignMarketing: false
            });
        } else  {
            Object.assign(tempPackages, packages)
            tempPackages[e.target.name] = e.target.checked;
        } 
        
        

        setPackages(tempPackages);
    } 

    const onSubmitActivationModal = () => {
        handleActivateUser(user._id, packages);
        setShowModal(false);
    } 

    const onResetUser = () => {
        handleDeactivateUser(user._id);
    }


    return (
        <div className="mt-3 flex items-center">
            <div className={"ml-5 text-2xl px-4 flex flex-grow justify-between items-center " + bgColorStyling + " rounded-xl"}>
                <span className="text-backgroundWhite">{user.name}</span>
                <div className="text-backgroundWhite flex justify-center items-center text-center">
                   
                    {!user.isAdmin && !Object.keys(user.packages).length ? <button onClick={openActivationModel} className="w-16 h-16 opacity-100 hover:opacity-40 bg-opacity-90 duration-300"><img alt="activate" src={ActivateIcon} /></button> : <button className="w-16 h-16 pointer-events-none"></button>}
                    {!user.isAdmin && Object.keys(user.packages).length  ? <button onClick={openAdsModel} className="w-16 h-16 opacity-100 hover:opacity-40 bg-opacity-90 duration-300"><img alt="add" src={AdsIcon}></img></button> : <></>}
                    {!user.isAdmin && Object.keys(user.packages).length ? <button onClick={onResetUser} className="w-16 h-16 opacity-100 hover:opacity-40 bg-opacity-90 duration-300"><img alt="reset" src={ResetIcon} /></button> : <button className="w-16 h-16 pointer-events-none"></button>}
                 </div>
            </div>
            <Modal title="Paketauswahl" showModal={showModal} setShowModal={setShowModal} onSubmit={onSubmitActivationModal} tagsValid={true}>
                <div className="my-16 mx-20">
                    <Package name="basic" title="Basis - 9.90€" onChange={changePackage}></Package>
                    <Package name="songRequest" title="Songwünsche - 3.00€" disabled={!packages.basic} onChange={changePackage}></Package>
                    <Package name="removeForeignMarketing" title="Keine Fremdwerbung - 3.00€" disabled={!packages.basic} onChange={changePackage}></Package>
                </div>
            </Modal>

        </div>
        
    );
};