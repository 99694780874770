import { useEffect, useRef, useState } from "react";
import { useRealmApp } from "../RealmApp";
import { asyncExecute, mapObject, replaceById } from "../utils/misc";
import { GetAdBlocksByUserRequest, GetAdByIdRequest, GetSongRequest, SyncRequest } from "../utils/yosoRequest";
import { useAudio } from "./useAudio";

const AudioType = {
	Ad: "ad",
	Song: "song",
}

export const QueueState = {
	/** The queue is currently in the process of being loaded */
	Loading: "loading",
	/** The queue has finished loading and is ready to be used */
	Loaded: "loaded",
	/** Loading of the queue has not started yet */
	NotLoaded: "notLoaded",
	Error: "error",
	/** Queue is currently used and songs are being loaded in the background */
	BackgroundLoading: "reloading",
}

function setAudioType(object, type) {
	return { ...object, type: type }
}

function setTypeSong(object) {
	return setAudioType(object, AudioType.Song);
}

function setTypeAd(object) {
	return setAudioType(object, AudioType.Ad);
}

/** **SET INITIAL GENRE TO PREVENT INITIAL ERROR STATE**
 * @param {String} initialGenre 
 * @param {Number} queueLength The length of the queue excluding ads
 * @param {Number} audiosToCache 
 */
export const usePlayer = (initialGenre, queueLength = 4, audiosToCache = 2) => {
	const app = useRealmApp();
	const {
		getRandomSongQueue,
		getSong,
		syncPlayer,
		getAdBlocksByUser,
		getAdById,
		clearSongRequests,
	} = app.currentUser.functions;


	//#region STATE

	// Contains the whole queue of audio
	// when setting State in callbacks use
	// ```JS
	// setAudioQueue(_audioQueue => {
	//   return calculatedQueue;
	// })```
	const [audioQueue, setAudioQueue] = useState([]);
	const audioQueueRef = useRef(audioQueue);
	audioQueueRef.current = audioQueue;

	const [selectedGenres, setSelectedGenres] = useState([initialGenre]);

	const [queueState, setQueueState] = useState(QueueState.NotLoaded);

	/** Only to group all states regarding the actual player */
	const playerState = {
		audioQueue, setAudioQueue,
		selectedGenres, setSelectedGenres,
	}


	// Only for songRequests
	// const { songRequests, refetch } = useSyncedPlayer(30000);

	// timeoutHandles for queueing the ads.
	const [adBlockTimeoutHandles, setAdBlockTimeoutHandles] = useState();
	// TODO: rewrite to use absolute times
	/** contains all deltatimes */
	const occupiedAdBlockTimesRef = useRef({});
	const adBlocksLoadedRef = useRef(false);

	//#endregion





	//#region GETTER
	const failsafeAudiosToCache = () => Math.min(audiosToCache, audioQueueRef.current.length);

	const currentAudio = () => audioQueueRef.current[0];

	const currentIsAd = () => currentAudio().type === AudioType.Ad;
	const currentIsSong = () => currentAudio().type === AudioType.Song;

	const playingAd = () => currentIsAd && audioIsPlaying;
	const playingSong = () => currentIsSong && audioIsPlaying;

	const songsInQueue = () => audioQueueRef.current.filter(audio => audio.type === AudioType.Song).length;
	//#endregion





	const { playing: audioIsPlaying, togglePlayPause: _togglePlayPause, setVolume, volume } = useAudio(currentAudio()?.file, playNextSong);





	//#region QUEUE LOGIC

	//INITIALIZATION
	const loadRandomSongQueue = () => {
		setQueueState(QueueState.Loading)

		if (!selectedGenres) {
			console.warn(`usePlayer: '${selectedGenres}' not valid as genre! Aborting loading of SongQueue`)
			setQueueState(QueueState.Error)
			return;
		}

		console.info(`usePlayer: Loading Random Song Queue for genre '${selectedGenres}'...`);

		let unmounted = false;
		const cleanupEffect = () => unmounted = true;

		// get 1 song less when currently playing
		const songsToGet = queueLength - (audioIsPlaying ? 1 : 0);

		getRandomSongQueue(songsToGet, selectedGenres).then(_songs => {
			if (unmounted) return;
			console.debug(`usePlayer: Loaded SongQueue for Genre=${selectedGenres}: `, _songs);

			_songs = _songs.map(setTypeSong);

			const ads = audioQueue.filter(audio => audio.type === AudioType.Ad);

			/// setup _songs to contain the currently playing audio and ads
			if (audioIsPlaying) {
				// preserve the currentAudio if currently Playing
				_songs = [currentAudio(), ...ads, ..._songs];
			} else {
				_songs = [...ads, ..._songs]
			}

			setAudioQueue(_songs);
			console.info(`usePlayer: Loaded queue with ${_songs.length} songs for Genre '${selectedGenres}'`);

			pushQueueToSync(_songs);
		});

		return cleanupEffect;
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(loadRandomSongQueue, [selectedGenres]);


	async function fillupQueue() {
		setQueueState(QueueState.BackgroundLoading);

		const songsToGet = queueLength - songsInQueue();
		console.log("songsToGet: ", songsToGet)
		if (songsToGet <= 0) return;

		const fillupSongs = await getRandomSongQueue(songsToGet, selectedGenres).then(songs => songs.map(setTypeSong));
		setAudioQueue([...audioQueueRef.current, ...fillupSongs]);

		setQueueState(QueueState.Loaded);
	}



	//#region SONGREQUEST
	// useEffect(() => {
	// 	console.info("usePlayer: songRequests: ", songRequests);

	// 	if (!audioQueue) {
	// 		console.warn("usePlayer: songRequests: NO QUEUE")
	// 		return
	// 	}

	// 	if (!songRequests || songRequests.length === 0) {
	// 		console.info("usePlayer: songRequests: no songRequests")
	// 		return
	// 	}

	// 	let tmpSongQueue = [...audioQueue]

	// 	for (const songRequest of songRequests) {
	// 		const index = firstPossibleQueueIndexForSong()
	// 		console.debug("usePlayer: songRequests: add: ", songRequest.song, " at ", index)
	// 		tmpSongQueue = insertAt(tmpSongQueue, songRequest.song, index)
	// 	}

	// 	console.log("usePlayer: songRequestgs: new audioQueue: ", tmpSongQueue)
	// 	setAudioQueue(tmpSongQueue);

	// 	clearSongRequests();
	// 	pushQueueToSync(tmpSongQueue, genre).then(refetch);
	// }, [songRequests])

	/** Returns the first possible index where a song could be queued
	 * i.e. first place after the current that is not an ad */
	function firstPossibleQueueIndexForSong() {
		let i = 1;

		while (i < audioQueue.length && audioQueue[i].type === AudioType.Ad) {
			i++;
		}

		return i;
	}

	function addSongToQueue(song) {
		const i = firstPossibleQueueIndexForSong();
		const newSongQueue = [...audioQueue.slice(0, i), { ...song }, ...audioQueue.slice(i)];
		console.log("usePlayer: addSongToQueue: newQueue: ", newSongQueue);
		setAudioQueue(newSongQueue);
	}

	/** Pushes the given `queue` and `genre` to sync (db)
	 * @param {[]} queue
	 * @param {string?} genre if not given will use the current genre of `playerState.genre`
	 * @returns Filtered queue */
	async function pushQueueToSync(queue, selectedGenres) {
		const allowedProperties = ["_id", "title", "artist", "type"];
		queue = queue.map(audio => mapObject(audio, allowedProperties))

		await syncPlayer(SyncRequest(queue, selectedGenres ?? playerState.selectedGenres))
		console.info("usePlayer: SYNC: ", queue)

		// return queue;
	}
	//#endregion
	//#endregion





	//#region AUDIO LOGIC
	// Handling of loading the actual files
	function loadAudio() {
		/** Returns a Promise to load the `qElement` */
		async function createRequest(qElement) {
			if (qElement.file) {
				// resolve those already having a `.file` attribute immediately
				return qElement;
			}

			switch (qElement.type) {
				case AudioType.Song:
					const song = await getSong(GetSongRequest(app.activeToken, qElement._id));
					return setTypeSong(song)

				case AudioType.Ad:
					const ad = await getAdById(GetAdByIdRequest(qElement._id.toString()))
					return setTypeAd(ad);

				default:
					throw new Error(`AudioType was not recognised, was: '${qElement.type}'`)
			}
		}



		if (!audioQueue?.length) {
			console.warn("usePlayer: loadAudio: ABORTED; no audio in queue");
			return;
		}

		const allAudioLoaded = audioQueue.slice(0, failsafeAudiosToCache()).every(audio => audio.file);
		console.debug("usePlayer: loadAudio: allAudioLoaded: ", allAudioLoaded)
		if (allAudioLoaded) {
			console.warn("usePlayer: loadAudio: ABORTED; all audio already loaded");
			return;
		}



		let unmounted = false;
		const cleanup = () => unmounted = true;

		const promises = [];
		const firstQElement = createRequest(audioQueue[0])

		// special handling for the first to load
		if (!audioQueue[0].file) {
			console.debug("usePlayer: loadAudio: firstQElement hasnt been loaded, so do fast set of state to show User quicker")

			firstQElement.then(loadedAudio => {
				if (unmounted) return;

				console.info("usePlayer: loadAudio: First Loaded: ", { ...loadedAudio, file: loadedAudio?.file ? "FILE" : "NO FILE" });
				setAudioQueue(replaceById([...audioQueue], loadedAudio));
				setQueueState(QueueState.BackgroundLoading)
			})
		} else {
			setQueueState(QueueState.BackgroundLoading)
		}



		// create promises for the rest
		const numOfRequest = failsafeAudiosToCache()
		for (let i = 1; i < numOfRequest; i++) {
			promises.push(createRequest(audioQueue[i]));
		}


		// Handle data
		Promise.all(promises).then(async loadedAudios => {
			if (unmounted) return;

			console.debug("usePlayer: loadAudio: loadedAudio (first audio was handled extern): ", loadedAudios);

			// because of the `await` at this point the first audio has been loaded
			let newAudioQueue = replaceById([...audioQueue], await firstQElement);
			for (const loadedAudio of loadedAudios) {
				newAudioQueue = replaceById(newAudioQueue, loadedAudio);
			}

			console.debug('usePlayer: loadAudio: newAudioQueue: ', newAudioQueue)

			setAudioQueue(newAudioQueue);
			setQueueState(QueueState.Loaded);
			console.log(`usePlayer: loadAudio: ${loadedAudios?.length + 1}/${audiosToCache}/${audioQueue.length} songs cached`);
		});

		return cleanup
	}
	useEffect(loadAudio, [audioQueue])

	//#endregion





	//#region AD LOGIC
	// Flow:
	// 1 Get AdBlocks
	// 2 create timeouts for each AdBlock
	// 3 When timeout executes queue all the ads of the adBlock to play after current audio

	//1
	function loadAdBlocks() {
		if (queueState !== QueueState.Loaded) {
			console.log("usePlayer: loadAdBlocks: queue not loaded yet")
			return;
		}

		if (adBlocksLoadedRef.current) {
			console.log("usePlayer: loadAdBlocks: adBlocks already loaded");
			return;
		}

		let unmounted = false;

		const cleanup = () => {
			unmounted = true;
			clearAdTimers();
		}

		getAdBlocksByUser(GetAdBlocksByUserRequest(app.currentUser.id)).then(_adBlocks => {
			if (unmounted || queueState !== QueueState.Loaded) return
			// 2
			adBlocksLoadedRef.current = true;
			console.debug("usePlayer: loadAdBlocks: adBlocks: ", _adBlocks);

			asyncExecute(() => createAdBlocksTimers(_adBlocks.map(adBlock => {
				// add `type` parameter to each ad
				adBlock.ads = adBlock.ads.map(setTypeAd)
				return adBlock
			})));
		});

		return cleanup;
	}
	useEffect(loadAdBlocks, [queueState]);


	// 2
	/** **Creates all Timers for all given `adBlocks`** */
	function createAdBlocksTimers(adBlocks) {
		const timerHandles = [];
		const now = new Date();

		for (const adBlock of adBlocks) {
			createAdBlockTimers(adBlock, now).forEach(timer => {
				timerHandles.push(timer.handle)
			})
		}

		setAdBlockTimeoutHandles(timerHandles);
	}

	function createAdBlockTimers(adBlock, now = new Date()) {
		const timerHandles = [];

		for (const time of adBlock.times.map(Number)) {
			const timerHandle = createAdBlockTimer(adBlock, time, now);
			if (timerHandle)
				timerHandles.push(timerHandle);
		
		}
		console.debug("usePlayer: createAdBlockTimers: created Timers for adBlock: ", adBlock);

		return timerHandles;
	}

	/** **Creates one Timer for the `adBlock` for the specified `time` using `now`** */
	function createAdBlockTimer(adBlock, time, now = new Date()) {
		/** oneSecond in milliseconds */
		const oneSecond = 1000;
		/** oneMinute in milliseconds */
		const oneMinute = 60 * oneSecond;

		/** In Minutes */
		let deltaTime = time < now.getMinutes() ? 60 - now.getMinutes() + time : time - now.getMinutes();
		// debug
		// let deltaTime = 0.01;

		if (deltaTime <= 0) {
			console.log(`usePlayer: createAdBlockTimer: skipping timer ${time} for ${adBlock.title} because would queue now`)
			return;
		}

		// 3
		while (occupiedAdBlockTimesRef.current[deltaTime]) {
			deltaTime += 0.01
		}

		console.debug(`usePlayer: createAdBlockTimer for ${adBlock.title} for time=${time}: deltaTime: `, deltaTime)

		const timer = { delta: deltaTime, handle: setTimeout(() => queueAdBlock(adBlock, time), deltaTime * oneMinute) };

		occupiedAdBlockTimesRef.current[deltaTime] = deltaTime;

		return timer;
	}

	//3
	/** **Adds the adblock to the queue**
	 * All ads of the adblock are added to the `audioQueue` */
	function queueAdBlock(adBlock, time) {
		const reSet = () => createAdBlockTimer(adBlock, time);

		console.log("usePlayer: queueAdBlock: adBlock: ", adBlock)

		if (!audioQueueRef.current || !audioQueueRef.current.length) {
			console.warn("usePlayer: queueAdBlock: audioQueue is empty")
			reSet()
			return;
		}

		console.debug("usePlayer: queueAdBlock: old audioQueue: ", audioQueueRef.current)
		const newAudioQueue = [audioQueueRef.current[0], ...adBlock.ads, ...audioQueueRef.current.slice(1)];

		console.debug("usePlayer: queueAdBlock: new audioQueue: ", newAudioQueue)

		setAudioQueue(newAudioQueue);

		reSet();
	}

	function clearAdTimers() {
		setAdBlockTimeoutHandles(undefined);

		for (const timer in adBlockTimeoutHandles) {
			clearTimeout(timer);
		}
	}
	//#endregion





	//#region HIGH-LAYER
	function togglePlayPause() {
		_togglePlayPause();
	};

	function playNextSong() {
		// as this is called from `useAudio` this has not access to the latest state directly
		setAudioQueue(_audioQueue => {
			const tmpAudioQueue = [..._audioQueue];
			tmpAudioQueue.shift();
			console.info("usePlayer: playNextSong: ", tmpAudioQueue);
			return tmpAudioQueue
		});

		fillupQueue();
	}

	function changeVolume(event) {
		setVolume(event.target.value);
	}
	//#endregion





	return {
		togglePlayPause, playNextSong, audioIsPlaying,
		volume, changeVolume,
		//
		audioQueue,
		selectedGenres, setSelectedGenres,
		//
		queueState,
		addSongToQueue,
	}
}