import React from 'react';
import { useHistory } from 'react-router-dom';

import { useRealmApp } from "../RealmApp";
import * as Realm from "realm-web";

import validator from "validator";

import Loading from '../components/Loading';

import handleAuthenticationError from '../utils/authentication-utils';

export default function Register() {
    const app = useRealmApp();

    const history = useHistory();

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [name, setName] = React.useState("");

    React.useEffect(() => {
        setName("Superfirma");
        setEmail("");
        setPassword("");
        setError({});
    }, []);

    const [error, setError] = React.useState({});

    const [isLoggingIn, setIsLoggingIn] = React.useState(false);

    const handleRegisterAndLogin = async (event) => {
        event.preventDefault()
        
        const isValidEmailAddress = validator.isEmail(email);
        setError((e) => ({ ...e, password: null }));
        if (isValidEmailAddress) {
          try {
            // Register the user and, if successful, log them in
            await app.emailPasswordAuth.registerUser({ email, password, name });
            return await handleLogin();
          } catch (err) {
            handleAuthenticationError(err, setError);
          }
        } else {
          setError((err) => ({ ...err, email: "Email is invalid." }));
        }
    }

    const handleLogin = async () => {
        setIsLoggingIn(true);

        setError((e) => ({
            ...e, 
            password: null 
        }));

        try {
            await app.logIn(Realm.Credentials.emailPassword(email, password));
            history.push("/");
        } catch (err) {
            handleAuthenticationError(err, setError);
            setIsLoggingIn(false);
            console.error(error);
        }

    };

    const handleInputChange = (event) => {
        const target = event.target;
        switch (event.target.id) {
            case "inputUsername":
                setEmail(target.value);
                break;
            case "inputPassword":
                setPassword(target.value);
                break;
            case "inputName":
                setName(target.value);
                break;
            default:
                break;
        }
    }


    return (
        <div className="flex h-screen bg-backgroundGrey">
            {isLoggingIn ? (
                <Loading />
             ) : (
                <div className="m-auto shadow-xl w-96 px-20 py-8 bg-backgroundWhite">
                    <h1 className='mx-auto my-8 text-center text-2xl'>Registrieren</h1>
                    <form onSubmit={(event) => handleRegisterAndLogin(event)}>
                        <div className="flex flex-col">
                            {/*<input className="text-foregroundBlack border border-borderGrey rounded-xl p-2 my-2" type="text" id="inputName" placeholder="Firmenname" value={name} onChange={handleInputChange}/>*/}
                            <input className="text-foregroundBlack border border-borderGrey rounded-xl p-2 my-2" type="text" id="inputUsername" placeholder="E-Mail" value={email} onChange={handleInputChange}/>
                            <input className="text-foregroundBlack  border border-borderGrey rounded-xl p-2 my-2" type="password" id="inputPassword" placeholder="Passwort" value={password} onChange={handleInputChange}/>
                            <input className="bg-gradient-to-r from-mainOrangeDark to-mainOrangeLight text-backgroundWhite rounded-xl p-2 my-2 cursor-pointer" type="submit" value="Registrieren" />
                            <p className="text-sm text-center mt-10">Sie haben bereits einen Account?</p>
                            <a href="/" className="text-sm text-center text-mainOrangeDark font-bold mb-5">Loggen Sie sich ein!</a>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
}