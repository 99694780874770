import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useRealmApp } from "../../RealmApp";

import handleAuthenticationError from '../../utils/authentication-utils';
import Button from './../generic/Button';

export default function AdminNav({active}) {
    const app = useRealmApp();

    const [error, setError] = React.useState({});
    const history = useHistory();

    useEffect(() => {
        console.error(error);
    }, [error]);

    const handleLogout = async () => {
        try {
            await app.logOut();
            history.push("/");
        } catch (err) {
            handleAuthenticationError(err, setError);
        }
    }

    return (
        <nav className="flex w-full bg-backgroundWhite py-3 justify-between items-center px-5">
            <div className="flex-1 text-left text-lg">{app.currentUser._profile.data.email}</div>
            <div className="flex-1 text-center text-lg items-center justify-center flex gap-5">
                <a href='/' className={active === 0 ?'font-bold text-mainOrangeDark' : ''}>Kunden</a>
                <a href="/songs/:genre" className={active === 1 ? 'font-bold text-mainOrangeDark' : ''}>Songs</a>
                <a href='/third-party-ads' className={active === 2 ? 'font-bold text-mainOrangeDark' : ''}>Fremdwerbungen</a>
            </div>
            <div className="flex-1 text-right text-lg">
                <Button onClick={handleLogout}>Ausloggen</Button>
            </div>
        </nav>
    );
};