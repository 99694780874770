/** Wraps `func` in an async call
 * @param {Function} func sync function */
export const asyncExecute = (func) => (async () => func())();

/** **Maps an object to only some Properties**
 * @param {*} object object that gets mapped
 * @param {*} allowedProperties an array of property-names that are allowed and get mapped.
 * @returns mapped object only containing `allowedProperties`
 */
export const mapObject = (object, allowedProperties) => {
	const mapped = {};

	for (const key of Object.keys(object)) {
		if (allowedProperties.includes(key)) {
			mapped[key] = object[key];
		}
	}

	return mapped;
}

export const removeDuplicates = (array, identifier) => {
	if (array.length <= 1 ) return array;
	if (!identifier) throw new Error("No identifier given to filter for duplicates.")

	const known = [];
	const filtered = [];

	for (const element of array) {
		if (!known.includes(element[identifier])) {
			filtered.push(element);
		}
		known.push(element[identifier])
	}

	return filtered;
}

export const replaceById = (array, object) => {
	const index = array.findIndex(element => element._id.toString() == object._id.toString());
	
	if (index === -1) return array;
	
	array[index] = object;

	return array;
}