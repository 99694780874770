import React from 'react'
import { useDropzone } from 'react-dropzone';
import Button from '../Button';

import FileUploadIcon from "../../../icons/icon_upload_black.svg";

export default function FileInput({name}) {
    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        accept: ".mp3",
        maxFiles: 1,
    });

    return (
        <div id="dropzone" {...getRootProps({ className: 'dropzone border-transparent rounded-lg appearance-none border border-borderGrey w-full py-2 px-4 bg-backgroundWhite text-foregroundBlack flex flex-col items-center gap-2' })}>
            <img src={FileUploadIcon}  alt="File Upload Icon" />
            <input name={name} {...getInputProps()} />
            {acceptedFiles.length != 0 ? <h2>{acceptedFiles[0]?.name}</h2> : <h2>Ziehe die .mp3 Datei hier rein</h2>}
            <Button onClick={open}>
                Datei hochladen
            </Button>
        </div>
    );
}