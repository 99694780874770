import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useRealmApp } from "../RealmApp";

import YosoLogo from './../images/logo_text_side.png';

import handleAuthenticationError from '../utils/authentication-utils';
import Button from './generic/Button';

export default function ClientNav() {
    const app = useRealmApp();

    const [error, setError] = React.useState({});
    const history = useHistory();

    useEffect(() => {
        console.error(error);
    }, [error]);

    const handleLogout = async () => {
        try {
            await app.logOut();
            history.push("/");
        } catch (err) {
            handleAuthenticationError(err, setError);
        }
    }

    return (
        <nav className="flex w-full bg-backgroundWhite py-3 justify-between items-center px-5">
            <div className="flex-1 text-left text-lg">{app.currentUser._profile.data.email}</div>
            <div className="flex-1 text-center text-lg">
                <img alt='Yoso Logo' className='mx-auto w-1/6' src={YosoLogo}></img>
            </div>
            <div className="flex-1 text-right text-lg">
                <Button onClick={handleLogout}>Ausloggen</Button>
            </div>
        </nav>
    );
};