import React, { useEffect, useState } from 'react';
import Loading from './components/Loading';
import useActiveSession from './hooks/useActiveSession';
import AdminDashboard from './pages/AdminDashboard';
import NotActivated from './pages/NotActivated';
import Radio from './pages/Radio';
import LimitedRadio from './pages/LimitedRadio';
import {isMobile} from 'react-device-detect';
import { useRealmApp } from "./RealmApp";
import SessionDialog from './pages/SessionDialog';

export default function PageWrapper() {
    const app = useRealmApp();

    return (
        <div>
            {
                app.currentUser?.customData?.isAdmin ? <AdminDashboard /> :
                    !app.currentUser?.customData?.isAdmin && app.currentUser?.customData?.packages && Object.keys(app.currentUser?.customData?.packages).length ?
                    <SessionView /> : 
                    <NotActivated></NotActivated>
            }
        </div>
    );
};

function SessionView() {
    const { activeSession, refetch } = useActiveSession();
    const [dialogAnswered, setDialogAnswered] = useState(false);
    const app = useRealmApp();
    const [initGenre, setInitGenre] = useState("Rock");
    const {
        activateSession,
        getAllAvailableGenres
    } = app.currentUser.functions;

    useEffect(() => {
        async function fetchData() {
            let _genres = await getAllAvailableGenres();
            setInitGenre(_genres[0]);
        }
        fetchData();
    }, []);

    const changeToActiveSession = async (isConfirmed) => {
        if (isConfirmed) {
            await activateSession({ token: app.activeToken });
            await app.currentUser.refreshCustomData();
            // app.storage.set("secondaryAccept", true)
            refetch();
        }
        setDialogAnswered(true);
    }

    return (
        !isMobile ?
            ((activeSession === false && !dialogAnswered) ?
            <SessionDialog changeToActiveSession={changeToActiveSession} /> :
            activeSession ?  
                <Radio initGenre={initGenre} /> :
                ((activeSession === false) ? <LimitedRadio /> : <Loading />)) :
        <LimitedRadio />
    )
}