/**
 * Create YosoRequests.
 * 
 * YosoRequests are used for requesting the realm-backend in an extensible way.
 *
 * @param {string} token SessionToken 
 * @param  {object} args Arbitrary Arguments
 */
export default function YosoRequest(token, args) {
	return { token: token, args: args }
}

/** Request to get a single Song
 * 
 * @param {string} songId the id of the song to get
 */
export const GetSongRequest = (token, songId) => {
	if (!songId) throw new NoIdError();
	return YosoRequest(token, { songId: songId });
}

// #region Sync
/** Request to sync the songQueue and genre
 * 
 * @param {[{_id: string, title: string}]} songQueue 
 * @param {string} genre 
 */
export const SyncRequest = (songQueue, genre) => {
	if (!songQueue || !genre) throw new NoPayloadError();

	return YosoRequest(null, { songQueue: songQueue, genre: genre });
}

// #region SongRequest
/** Request to add a songrequest
 * 
 * @param {{song: any, timestamp: Date}} songRequest 
 */
export const SongRequestRequest = (songRequest) => {
	return YosoRequest(null, songRequest)
}

/** SongRequest-Object */
export function SongRequest(song) {
	return { song: song, timestamp: new Date() }
}
// #endregion
// #endregion

// #region Ad
/** Request to upload an ad.
 * @param {string} token
 * @param {{file: object, title: string, type: ("Fremdwerbung"|"Eigenwerbung"|"Jingle"), userId: string?}} ad The add to save. `user` can only be null if `type` is `Fremdwerbung`
 */
export const UploadAdRequest = (ad) => {
	if (!ad) throw new NoPayloadError();

	console.log(ad);

	if (ad.type !== "Fremdwerbung" && !ad.userId) {
		throw new Error("UploadAdRequest: User has to be given, if type !== 'Fremdwerbung'");
	}

	return YosoRequest(null, { ad: ad });
}

/**
 * 
 * @param {string} userId 
 * @param {[("Fremdwerbung"|"Eigenwerbung"|"Jingle")]} ignoreTypes 
 * @returns 
 */
export const GetAdsByUserRequest = (userId, ignoreTypes) => {
	if (!userId) throw new NoIdError();
	return YosoRequest(null, { userId: userId, types: ignoreTypes });
}

export const GetAdByIdRequest = (adId) => {
	if (!adId) throw new NoIdError();
	return YosoRequest(null, { adId: adId });
}

export const DeleteAdRequest = (adId) => {
	if (!adId) throw new NoIdError();
	return YosoRequest(null, { ad: adId });
}

// #endregion

// #region AdBlock

export const CreateAdBlockRequest = (adBlock) => {
	if (!adBlock) throw new NoPayloadError();
	return YosoRequest(null, { adBlock: adBlock });
}

export const GetAdBlocksByUserRequest = (userId) => {
	if (!userId) throw new NoIdError();
	return YosoRequest(null, { userId: userId });
}

export const DeleteAdBlockRequest = (adBlockId) => {
	if (!adBlockId) throw new NoIdError();
	return YosoRequest(null, { adBlock: adBlockId });
}

// #endregion

// #region Errors 

class NoIdError extends Error {
	constructor() {
		super("No Id");
	}
}

class NoPayloadError extends Error {
	constructor() {
		super("No Payload");
	}
}

//#endregion