import { BrowserRouter, Route, Switch } from "react-router-dom";

import RealmApolloProvider from "./graphql/RealmApolloProvider";
import { useRealmApp, RealmAppProvider } from "./RealmApp";

import Login from "./pages/Login.jsx";
import Register from "./pages/Register.jsx";
import PageWrapper from "./PageWrapper";
import AdsOverview from './pages/AdsOverview.jsx';
import NotFound from "./pages/NotFound";
import ThirdPartyAdsOverview from "./pages/admin/ThirdPartyAdsOverview";
import ThirdPartyAdsCreateEdit from "./pages/admin/ThirdPartyAdsCreateEdit";
import AdsCreateEdit from "./pages/admin/AdsCreateEdit";
import AdBlocksCreateEdit from "./pages/admin/AdBlocksCreateEdit";
import SongsOverview from "./pages/admin/SongsOverview";

export const APP_ID = "yoso-jleqt"; //Production Version


const RequireLoggedInUser = ({ children }) => {
  // Only render children if there is a logged in user.
  const app = useRealmApp();
  return app.currentUser && app.activeToken ? 
    children : 
    (
        <RealmApolloProvider>
          <div>
            <BrowserRouter>
              <Switch>
                <Route exact path="/">
                  <Login></Login>
                </Route>
                <Route exact path="/register">
                  <Register></Register>
                </Route>
              </Switch>
            </BrowserRouter>
          </div>  
        </RealmApolloProvider>
    )

    ;
};

function App() {
 
  return (
  <RealmAppProvider appId={APP_ID}>
      <RequireLoggedInUser>
        <RealmApolloProvider>
          <div>
            <BrowserRouter>
              <Switch>
                <Route exact path="/">
                  <PageWrapper></PageWrapper>
                </Route>
                  {/* //TODO: ADMIN */}
                    <Route exact path="/ads/:userid">
                      <AdsOverview></AdsOverview>
                    </Route>
                    <Route exact path="/third-party-ads">
                      <ThirdPartyAdsOverview></ThirdPartyAdsOverview>
                    </Route>
                    <Route exact path="/songs/:genre">
                      <SongsOverview></SongsOverview>
                    </Route>
                    <Route path="/create-edit-third-party-ads">
                      <ThirdPartyAdsCreateEdit></ThirdPartyAdsCreateEdit>
                    </Route>
                    <Route exact path="/create-edit-ads/:userid">
                      <AdsCreateEdit></AdsCreateEdit>
                    </Route>
                    <Route exact path="/create-edit-ad-blocks/:userid">
                      <AdBlocksCreateEdit></AdBlocksCreateEdit>
                    </Route>
                <Route>
                  <NotFound></NotFound>
                </Route>
              </Switch>
            </BrowserRouter>
          </div>
        </RealmApolloProvider>
      </RequireLoggedInUser>
    </RealmAppProvider>
  );
}

export default App;
