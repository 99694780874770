import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Form from "../../components/generic/form/Form";
import TextInput from "../../components/generic/form/TextInput";
import Skeleton from "../../components/generic/Skeleton";
import Table from "../../components/generic/Table";
import AdminNav from "../../components/nav/AdminNav";
import { useRealmApp } from "../../RealmApp";
import parseFormData from "../../utils/formDataParser";
import { CreateAdBlockRequest, GetAdsByUserRequest } from "../../utils/yosoRequest";

export default function AdBlocksCreateEdit() {
    const app = useRealmApp();
    const {
        createAdBlock,
        getAdsByUser,

    } = app.currentUser.functions;

    const { userid } = useParams();
    const [isLoading, setLoading] = useState(false);
    const [ads, setAds] = useState([]);
    const [selectedAds, setSelectedAds] = useState([]);
    const history = useHistory();

    useEffect(() => {
        async function fetchData() {
            let _ads = await getAdsByUser(GetAdsByUserRequest(userid, []));
            setAds(_ads);
            setLoading(false);
        }
        fetchData();
    }, [userid])

    const createEditAdBlock = (e) => {
        e.preventDefault();
        let formData = parseFormData(e);
        console.log(formData);
        createAdBlock(CreateAdBlockRequest({title: formData.title, ads: selectedAds, userId: userid, times: formData.times.split(",")}))
        history.push("/ads/" + userid)
    }

    const addAdToAddBlock = (row) => {
        setSelectedAds([...selectedAds, row._id]);
    }


    return (
        <div className="h-screen bg-backgroundGrey flex flex-col">
            <AdminNav 
                active={0}
            />
            <Form
                heading="Werbeblöcke erstellen"
                submitText="Erstellen"
                onSubmit={createEditAdBlock}
                loading={isLoading}
            >
                <TextInput name="title" placeholder="Titel"></TextInput>
                <TextInput name="times" placeholder="Zeiten (10,30,50)"></TextInput>
                <label htmlFor="">Werbungen</label>
                {
                     selectedAds.length !== 0 && selectedAds.map((element, index) => {
                        return (
                            <div key={index} className=" rounded-lg border-transparent flex-1 appearance-none border border-borderGrey w-full py-2 px-4 bg-mainOrangeDark text-backgroundWhite placeholder-borderGrey shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent my-1">
                                {ads.find(ad => ad._id === element)?.title}
                            </div>
                        )
                    })
                }
            </Form>
            <div>
            {!isLoading ? <Table 
                    heading="Jingles, Eigenwerbung und Fremdwerbung"
                    data={ads}
                    columns={[
                        {
                            title: "Titel",
                            render: (obj) => {
                                return obj.title
                            }
                        },
                        {
                            title: "Typ",
                            render: (obj) => {
                                return obj.type
                            }
                        },
                    ]}
                    actions={[
                        {
                            title: "Select",
                            onClick: (element) => addAdToAddBlock(element),
                        }
                    ]}
                /> : <Skeleton />}
            </div>
        </div>
    )
}