function parseFormData(e) {
    let target   = e.target;
    let formData = {};

    for (let i = 0; i < target.length; i++) {
        if (target.elements[i].getAttribute("type") === "radio") {
            target.elements[i].checked && (formData[target.elements[i].getAttribute("name")] = target.elements[i].value);
        }
        else if (target.elements[i].getAttribute("name") === "file"){
            formData[target.elements[i].getAttribute("name")] = target.elements[i].files[0]
        }
        else if (target.elements[i].getAttribute("name")) {
            formData[target.elements[i].getAttribute("name")] = target.elements[i].value;
        }
        
    }

	return formData;
}

export default parseFormData;