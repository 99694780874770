import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Skeleton from "../../components/generic/Skeleton";
import Table from "../../components/generic/Table";
import AdminNav from "../../components/nav/AdminNav";
import { useRealmApp } from "../../RealmApp";
import { GetAdsByUserRequest } from "../../utils/yosoRequest";

export default function ThirdPartyAdsOverview() {
    const app = useRealmApp();
    const {
        getAdsByUser,
    } = app.currentUser.functions;

    const [isLoading, setIsLoading] = useState(true);
    const [ads, setAds] = useState([]);

    const history = useHistory();

    useEffect(() => {
        async function fetchData() {
            let _ads = await getAdsByUser(GetAdsByUserRequest(app?.currentUser?.customData._id, ["Eigenwerbung", "Jingles"]));
            setAds(_ads);
            setIsLoading(false);
        }
        fetchData();
    }, []);

    const navigateToCreateEditThirdPartyAds = () => {
        history.push("/create-edit-third-party-ads");
    }

    return (
        <div className="bg-backgroundGrey h-screen flex flex-col">
            <AdminNav 
                active={2}
            />
            <div  className="mt-10">
            {!isLoading ? <Table 
                    heading="Fremdwerbungen"
                    data={ads}
                    columns={[
                        {
                            title: "Titel",
                            render: (obj) => {
                                return obj.title
                            }
                        },
                    ]}
                    onButtonClick={
                        navigateToCreateEditThirdPartyAds
                    }
                /> : <Skeleton />}
            </div>
        </div>
    )
}