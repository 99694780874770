import React, { useEffect, useState } from 'react';

import ClientNav from "../components/ClientNav.jsx";
import PlayMenu from "../components/PlayMenu.jsx";
import Button from "../components/generic/Button.jsx";
import IconButton from "../components/Button.jsx";

import StarIcon from "../icons/icon_star.svg";
import ComboBox from '../components/ComboBox.jsx';
import SongQueue from '../components/SongQueue.jsx';
// import { usePlayer } from '../hooks/useAudio.js';
import { useRealmApp } from '../RealmApp.js';
import SongRequestModal from '../components/SongRequestModal.jsx';



import Table from '../components/generic/Table';
import Modal from '../components/Modal.jsx';

import emailjs, { init as initEmailJs } from '@emailjs/browser';
import { QueueState, usePlayer } from '../hooks/usePlayer.js';


/* Player view for users, with activeSession **/
export default function Radio({initGenre}) {
    const app = useRealmApp();

    const {
        getAllSongsInfo,
        getAllAvailableGenres
    } = app.currentUser.functions;

    initEmailJs(process.env.REACT_APP_EMAILJS_INIT);

    // const {
    //     songQueue, loaded,
    //     playing, volume, genre: selectedGenre,
    //     togglePlayPause, playNextSong, changeVolume,
    //     playingAd,
    //     setGenre,
    //     addSongToQueue
    // } = usePlayer();


    const [songRequestModalVisible, setSongRequestModalVisible] = useState(false)
    const { availableGenres } = useAvailableGenres();
    const [showSongRequestModal, setShowSongRequestModal] = useState(false);
    const [songsForSongRequest, setSongsForSongRequest] = useState([]);
    const [allAvailableGenres, setAllAvailableGenres] = useState([]);
    const [songs, setSongs] = useState([]);
    const [page, setPage] = useState(0);
    const [availablePages, setAvailablePages] = useState(0);
    const [searchBarValue, setSearchBarValue] = useState("");

    const {
		togglePlayPause, playNextSong, audioIsPlaying: playing,
		volume, changeVolume,
		//
		audioQueue: songQueue,
		selectedGenres, setSelectedGenres,
		//
		queueState,
		addSongToQueue,
    } = usePlayer(initGenre);


    useEffect(() => {
        async function fetchData() {
            let _songs = await getAllSongsInfo();
            setSongs(_songs);
            setSongsForSongRequest(_songs);
            setAvailablePages(Math.floor(_songs.length / 3));
        }
        fetchData();
    }, []);

    const searchBarChange = (e) => {
        const value = e.target.value;
        setSearchBarValue(value);
        setSongsForSongRequest([...songs].filter(element => element.title.includes(value)))

    }

    const sendMail = () => {
        console.log(searchBarValue);

        var templateParams = {
            from_name: app.currentUser.customData.name,
            message: searchBarValue
        };


        emailjs.send('service_cf9ybwa', 'template_fp3z17g', templateParams)
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
            }, (error) => {
                console.log('FAILED...', error);
            });
    }

    const addRemoveSelection = (select) => {
        const foundItem = selectedGenres.find(_genre => _genre === select);

        if (foundItem) {
            let copy = [...selectedGenres];
            copy.splice(copy.findIndex(_genre => _genre === select), 1);

            if (copy.length === 0) {
                copy.push(availableGenres[0])
            }

            setSelectedGenres([...copy]);
            console.log([...copy])
        } else {
            setSelectedGenres([...selectedGenres, select])
            console.log([...selectedGenres, select]);
        }
    }

    useEffect(() => {
        setAvailablePages(Math.floor(songsForSongRequest.length / 3));
    }, [songsForSongRequest])

    const loaded = queueState === QueueState.Loaded || queueState === QueueState.BackgroundLoading;

    return (
        <div className="h-screen bg-backgroundGrey flex flex-col">
            <ClientNav
            />
            <h1 className="text-center text-3xl mt-5">Warteschlange</h1>
            <div className="mx-24 flex mt-5 justify-between items-center">
                <div className="justify-start w-1/3">
                    <IconButton
                        buttonText="Songwunsch"
                        buttonImage={StarIcon}
                        onClick={() => setSongRequestModalVisible(true)}
                        disabled={!app.currentUser.customData?.packages?.songRequest}
                    />
                </div>
                <PlayMenu
                    className="flex-grow-0 justify-center w-1/3"
                    onForward={playNextSong}
                    onVolumeChange={changeVolume}
                    volume={volume}
                    playing={playing}
                    toggle={togglePlayPause}
                    loaded={loaded}
                />
                <div className="flex justify-end w-1/3">
                    <ComboBox
                        selected={selectedGenres}
                        setSelected={addRemoveSelection}
                        items={
                            availableGenres
                        }
                    />
                </div>
            </div>
            <div className="flex mt-5 items-center">
                <div className="flex-1">
                    <SongQueue
                        songList={songQueue}
                    />
                </div>
            </div>
            <SongRequestModal
                addSongToQueue={(...params) => {
                    onSongRequest();
                    addSongToQueue(...params);
                }}
                modalVisible={songRequestModalVisible}
                setModalVisible={setSongRequestModalVisible}
            />
        </div>
    );

    function onSongRequest() {
        setSongRequestModalVisible(false);
    }
};

/** Returns all available Genres */
function useAvailableGenres() {
    const app = useRealmApp();

    const {
        getAllAvailableGenres
    } = app.currentUser.functions;

    const [availableGenres, setAvailableGenres] = useState([]);

    useEffect(() => {
        let unmounted = false;

        getAllAvailableGenres().then(genres => {
            if (unmounted) return;
            setAvailableGenres(genres);
            console.debug("Loaded Genres");
        });

        return () => unmounted = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        availableGenres,
    };
}