import React, { useEffect, useState } from "react";
import Button from "../components/Button";
import ClientNav from "../components/ClientNav";
import SongQueue from "../components/SongQueue";
import SongRequestModal from "../components/SongRequestModal";
import useSyncedPlayer from "../hooks/useSyncedPlayer"

import StarIcon from "../icons/icon_star.svg";

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

/* Player view for users, with secondarySession **/
export default function LimitedRadio() {
	const { songQueue, genre, addSongToQueue, songRequests, refetch } = useSyncedPlayer(10000);

	const [songRequestModalVisible, setSongRequestModalVisible] = useState(false)

	console.log("SongRequests: ", songRequests)

	const onSongRequest = () => {
		const boosts = 5;
		const boostInterval = 2000;
		
		// refetch songQueue after modal closed
		console.log("Updating data with boosts")
		for (let boost = 0; boost < boosts; boost++) {
			setTimeout(() => {
				console.log(`Boost: ${boost}`)
				refetch()
			}, boost*boostInterval);
		}

		setSongRequestModalVisible(false);
	}
	

	return (

		<div className="h-screen bg-backgroundGrey flex flex-col">
			<ClientNav
			/>
			<h1 className="text-center text-3xl mt-5">Limited </h1>
			{!isMobile ?
				<div className="mx-24 flex mt-5 justify-between items-center">
					<div className="justify-start w-1/3">
						<Button
							buttonText="Songwunsch"
							buttonImage={StarIcon}
							onClick={() => setSongRequestModalVisible(true)}
						/>
					</div>
					<div className="flex justify-end w-1/3">
						<div className="text-2xl border-2 border-mainOrangeDark rounded-xl text-mainOrangeDark  px-20 py-2 text-center cursor-default focus:outline-none">
							{genre && genre.map((option) =>option).join(' | ')}
						</div>
					</div>
				</div> : <div className="mx-24 flex flex-col gap-3 mt-5 justify-between items-center">
					<div className="justify-center">
						<Button
							buttonText="Songwunsch"
							buttonImage={StarIcon}
							onClick={() => setSongRequestModalVisible(true)}
						/>
					</div>
					<div className="flex justify-center">
						<div className="text-2xl border-2 border-mainOrangeDark rounded-xl text-mainOrangeDark  px-20 py-2 text-center cursor-default focus:outline-none">
							{genre && genre.map((option) =>option).join(' | ')}
						</div>
					</div>
				</div>
			}
			<div className="flex mt-5 items-center">
				<div className="flex-1">
					<SongQueue
						songList={songQueue}
					/>
				</div>
			</div>

			<SongRequestModal
				addSongToQueue={(...params) => {
					onSongRequest()
					addSongToQueue(...params);
				}}
				modalVisible={songRequestModalVisible}
				setModalVisible={setSongRequestModalVisible}
			/>
		</div>
	);
}