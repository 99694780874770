import Loading from "../../Loading"

export default function Form({ heading, submitText, onSubmit, loading, children }) {
    return (
        <div className="container mx-auto px-4 sm:px-8 max-w-3xl">
            <div className="py-8">
                <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full">
                    <h2 className="text-2xl leading-tight">
                        {heading}
                    </h2>
                    <div className="text-end">
                        <form className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center">
                            <div className=" relative ">
                                {/*TODO: Invisible */}
                                <input type="text" id="&quot;form-subscribe-Filter" className="invisible rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-backgroundWhite text-foregroundBlack placeholder-borderGrey shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Suche..." />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                    <form action="#" encType="application/json" onSubmit={onSubmit}>
                        {
                            children.map(element => {
                                return (
                                    <div className="flex flex-col mb-2">
                                        <div className=" relative ">
                                            {element}
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                        /*<div className="flex flex-col mb-2">
                            <div className=" relative ">
                                <input type="text" id="create-account-pseudo" className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-backgroundWhite text-foregroundBlack placeholder-borderGrey shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" name="pseudo" placeholder="Pseudo" />
                            </div>
                        </div>
                        <div className="flex flex-col mb-2">
                            <div className=" relative ">
                                <input type="text" id="create-account-email" className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-backgroundWhite text-foregroundBlack placeholder-borderGrey shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Email" />
                            </div>
                        </div>
                        <div className="flex flex-col mb-2">
                            <div className=" relative ">
                                <input type="text" id="create-account-email" className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-backgroundWhite text-foregroundBlack placeholder-borderGrey shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Email" />
                            </div>
                        </div>
                        <div className="flex w-full my-4">
                            <button type="submit" className="py-2 px-4  bg-mainOrangeDark hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-backgroundWhite w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                                {submitText}
                            </button>
                        </div>*/
                        }
                        <div className="flex w-full my-4">
                            {loading ? 
                            <Loading />:
                            <button type="submit" className="py-2 px-4  bg-mainOrangeDark hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-backgroundWhite w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                                {submitText}
                            </button>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}