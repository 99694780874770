import React from 'react';
import Button from '../components/Button';

export default function SessionDialog({ changeToActiveSession }) {
    return (
        <div className="h-screen bg-backgroundGrey flex items-center justify-center">
            <div className='flex flex-col justify-center items-center gap-6'>
                <h1 className='text-3xl'>Es existiert bereits eine laufende Session!</h1>
                <div className='flex flex-row mx-auto justify-center gap-6'>
                    <Button buttonText="Diese Session zur aktiven Session machen" onClick={() => {changeToActiveSession(true)}} />
                    <Button buttonText="In die eingeschränkte Sicht wechseln" isSecondary onClick={() => {changeToActiveSession(false)}} />
                </div>
            </div>
        </div>
    )
}