import { useState } from "react";
import { useHistory } from "react-router-dom";
import FileInput from "../../components/generic/form/FileInput";
import Form from "../../components/generic/form/Form";
import TextInput from "../../components/generic/form/TextInput";
import AdminNav from "../../components/nav/AdminNav";
import { useRealmApp } from "../../RealmApp";
import parseFormData from "../../utils/formDataParser";
import { UploadAdRequest } from "../../utils/yosoRequest";;

export default function ThirdPartyAdsCreateEdit() {
    const app = useRealmApp();
    const {
        uploadAd,
    } = app.currentUser.functions;
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const createEditThirdPartyAd = (e) => {
        e.preventDefault();
        let formData = parseFormData(e);

        if (formData.file) {
            const reader = new FileReader();
            reader.onload = async function (evt) {
                const contents = evt.target.result;
    
                setLoading(true);
                await uploadAd(UploadAdRequest({ title: formData.title, file: contents, type: "Fremdwerbung",userId: app.currentUser.customData._id }));
                setLoading(false);
            };
            reader.readAsDataURL(formData.file);
            history.push("/third-party-ads")
        }

    }

    return (
        <div className=" bg-backgroundGrey h-screen flex flex-col">
            <AdminNav 
                active={2}
            />
            <Form
                heading="Erstelle Fremdwerbung"
                submitText="Erstelle"
                onSubmit={createEditThirdPartyAd}
                loading={loading}
            >
                <TextInput name="title" placeholder="Titel"></TextInput>
                <FileInput name="file"></FileInput>
            </Form>
        </div>
    )
}