import React, { useEffect } from 'react';

import Loading from '../components/Loading';

import { useRealmApp } from "../RealmApp";
import * as Realm from "realm-web";

import YosoLogo from './../images/logo_text_side.png';

import handleAuthenticationError from '../utils/authentication-utils';

export default function Login() {
    const app = useRealmApp();

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");

    const [error, setError] = React.useState({});

    const [isLoggingIn, setIsLoggingIn] = React.useState(false);

    useEffect(() => {
        setEmail("");
        setPassword("");
        setError({});
    }, []);

    /*React.useEffect(() => {
        setIsLoggingIn(false);
        console.log(error);
    }, [error])*/

    useEffect(() => {
        console.error(error);
    }, [error])


    const handleLogin = async () => {
        setIsLoggingIn(true);

        // Ambiguous error object when syncQueue fails...
        setError((e) => ({
            ...e, 
            password: undefined 
        }));

        try {
            await app.logIn(Realm.Credentials.emailPassword(email, password));
        } catch (err) {
            setIsLoggingIn(false);

            if (!handleAuthenticationError(err, setError)) {
                console.error("When trying to log in the following error occoured: ", err)
                throw err;
            }
        }

    };

    const handleInputChange = (event) => {
        const target = event.target;
        switch (event.target.id) {
            case "inputUsername":
                setEmail(target.value)
                break;
            case "inputPassword":
                setPassword(target.value)
                break;
            default:
                break;
        }
    }

    return (
        <div className="flex h-screen bg-backgroundGrey">
            {isLoggingIn ? (
                <Loading />
             ) : (
                <div className="m-auto shadow-xl w-96 px-20 py-8 bg-backgroundWhite">
                    <img alt='Yoso Logo' className='mx-auto my-8' src={YosoLogo}></img>
                    <form onSubmit={handleLogin}>
                        <div className="flex flex-col">
                            <input className="text-foregroundBlack border border-borderGrey rounded-xl p-2 my-2" type="text" id="inputUsername" placeholder="E-Mail" value={email} onChange={handleInputChange}/>
                            <input className="text-foregroundBlack  border border-borderGrey rounded-xl p-2 my-2" type="password" id="inputPassword" placeholder="Passwort" value={password} onChange={handleInputChange}/>
                            <input className="bg-gradient-to-r from-mainOrangeDark to-mainOrangeLight text-backgroundWhite rounded-xl p-2 my-2 cursor-pointer" type="submit" value="Einloggen" />
                            <p className="text-sm text-center mt-10">Noch keinen Account?</p>
                            <a href="/register" className="text-sm text-center text-mainOrangeDark font-bold mb-5">Registrieren Sie sich!</a>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
}