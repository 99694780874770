import React, { useState } from 'react';
import Chip from './Chip';
//import AddIcon from '@material-ui/icons/Add';
import { useEffect } from 'react';
import Button from './Button';

import { useRealmApp } from "../RealmApp";

export default function GenreField(props) {
    const { genres, setGenres } = props;

    const [availableGenres, setAvailableGenres] = useState([]);
    const [currentGenre, setCurrentGenre] = useState("");

    const app = useRealmApp();
    const {
        getAllAvailableGenres,
    } = app.currentUser.functions;


    useEffect(() => {
        async function fetchData() {
            let _availableGenres = await getAllAvailableGenres();
        
            setAvailableGenres(_availableGenres.filter(n => !genres.includes(n)));
        }
        fetchData();
    }, [genres])

    const handleCloseGenre = (genreToDelete) => {
        setGenres(genres.filter(genre => genre !== genreToDelete));
    }

    const handleAddGenre = () => {
        if (currentGenre !== "" && genres.filter(genre => genre === currentGenre))
        {
            setGenres([...genres, currentGenre].sort());
        }
        setCurrentGenre("");
    }    
    
    const handleClickGenre = async (genreClicked) => {
        setGenres([...genres, genreClicked]);
    }


    return (
        <div>
            <div className='flex'>
                <input className="flex-1 text-foregroundBlack border border-borderGrey rounded-xl p-2 my-2" type="text" id="inputGenre" placeholder="Genre Tag" value={currentGenre} onChange={e => setCurrentGenre(e.target.value)}/>
                <div className="m-2">
                    <Button  buttonText="+" onClick={handleAddGenre}></Button>
                </div>
            </div>
            <div className='flex my-2  flex-col'>
                <h4>Ausgewählte Genres</h4>
                <div className='flex flex-row'>
                {
                    genres.map((genre) => {
                        
                        return (
                            <Chip key={"key_" + genre} title={genre} handleClose={() => handleCloseGenre(genre)}/>
                        )
                    })
                }
                </div>
            </div>
            <div className='flex my-2 flex-col'>
                <h4>Verfügbare Genres</h4>
                <div className='flex flex-row'>
                {
                    availableGenres.map((availableGenre) => {
                        
                        return (
                            <Chip key={"key_" + availableGenre} title={availableGenre} handleClick={() => handleClickGenre(availableGenre)}/>
                        )
                    })
                }
                </div>
            </div>
        </div>
        /*<div>
                <div className="flex">
                <div className="mb-2">
                    <label htmlFor="tag">Tags</label>
                    <input
                        value={currentTagValue}
                        onChange={e => setCurrentTagValue(e.target.value)}
                        name="tag"
                        className={`inputField form-control shadow-none`}
                        autoComplete="off"
                    />
                </div>
                    <div className="buttonBarWrapper">
                        <label htmlFor="tagButton">
                            &nbsp; 
                        </label>
                        <button name="tagButton" className="btn btn-primary btnAction form-control p-0 ml-2" type="button" onClick={() => handleAddTag()}>+</button>
                    </div>
       
                </div><br />
                <div className="mb-2">
            <label htmlFor="tag">Ausgewählte Tags</label>
            <div className="d-flex flex-wrap">
                {
                    tags.map((tag) => {
                        
                        return (
                            <Chip key={"key_" + tag} title={tag} handleClose={() => handleCloseTag(tag)}/>
                        )
                    })
                }
            </div>
            </div>
            {
                availableTags && <>
                    <label htmlFor="tag">Verfügbare Tags</label>
                    <div className="d-flex flex-wrap">
                        {
                            availableTags.map((availableTag) => {

                                return (
                                    <Chip key={"key_" + availableTag} title={availableTag} handleClick={() => handleClickTag(availableTag)} />
                                )
                            })
                        }
                    </div>
                </>
            }
        </div>*/
    );
}
