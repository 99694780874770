import { useEffect, useState } from "react";
import { useRealmApp } from "../RealmApp";
import { SongRequest, SongRequestRequest as AddSongRequestRequest } from "../utils/yosoRequest";
import useRefetch from './useRefetch';

/**
 * Synced SongQueue and Genre
 * @param {number} pollingInterval in milliseconds
 */
export default function useSyncedPlayer(pollingInterval = 60000) {
	const app = useRealmApp();

	/** @type {[[]]} */
	const [songQueue, setSongQueue] = useState();
	const [genre, setGenre] = useState();
	const [songRequests, setSongRequests] = useState([]);

	const { trigger, refetch } = useRefetch();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(createSongQueueWatcher, [app]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(update, [trigger]);

	/** Updates the current user's customData to get new data */
	async function inlineUpdate() {
		await app.currentUser.refreshCustomData();

		return {
			songQueue: app.currentUser.customData.songQueue,
			genre: app.currentUser.customData.genre,
			songRequests: app.currentUser.customData.songRequests
		}
	}

	/** Updates songQueue and genre with newer values from the currentUser */
	function update() {
		let unmounted = false;

		inlineUpdate().then(() => {
			if (unmounted) return;

			console.log("useSyncedPlayer: updates: ", app.currentUser.customData)
			setSongQueue(app.currentUser.customData.songQueue);
			setGenre(app.currentUser.customData.genre);
			setSongRequests(app.currentUser.customData.songRequests)
			console.log("useSyncedPlayer: updated songQueue, genre and songRequests");
			console.debug("useSyncedPlayer: usr.customData: ", app.currentUser.customData);
		});

		return () => unmounted = true;
	}

	/** Also Updates the songQueue initially */
	function createSongQueueWatcher() {
		update();

		if (pollingInterval) {
			const i = setInterval(update, pollingInterval);
			return () => clearInterval(i);
		}

		// let changeWatcher
		// (async () => {
		// 	changeWatcher = users.watch();
		// 	for await (const change of changeWatcher) {
		// 		console.debug("CHANGE: ", change);
		// 	}
		// })();
		// return () => changeWatcher.return();
	}

	/** Ads a song to the synced queue */
	function addSongToQueue(songToAdd) {
		console.log("Adding Song to queue: ", songToAdd);

		app.currentUser.functions.addSongRequest(AddSongRequestRequest(SongRequest(songToAdd)))
			.then(() => console.log("useSyncedPlayer: addSongToQueue: pushed!"));
		
		// find first spot, where not adBlock
		let i = 1;
    while (i < songQueue.length && songQueue[i].times) {
      i++;
    }
		setSongQueue([...songQueue.slice(0, i), songToAdd, ...songQueue.slice(i)])
	}

	return {
		songQueue,
		genre,
		songRequests,

		addSongToQueue,
		manualUpdate: inlineUpdate,
		/** Fetches the songQueue manually, can be used for logical updating. eg. user presses skip */
		refetch,
	}
}