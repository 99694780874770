import React from 'react';

export default function Button(props) {
    let { buttonText, buttonImage, onClick, isSecondary, disabled} = props;
    
    return (
        !isSecondary ? 
        <button className={"flex justify-around items-center px-4 py-2 text-2xl text-backgroundWhite rounded-xl " + (!disabled ? "bg-gradient-to-r from-mainOrangeDark to-mainOrangeLight" : "bg-borderGrey")} disabled={disabled} onClick={onClick}>
           {buttonImage && <img src={buttonImage}  alt={buttonText} />}
           <span className={buttonImage && "ml-4"}>{ buttonText }</span>
        </button> :
        <button className={"flex justify-around items-center px-4 py-2 text-2xl text-foregroundBlack rounded-xl " + (!disabled ? "bg-gradient-to-r from-mainOrangeDark to-mainOrangeLight" : "bg-borderGrey")} disabled={disabled} onClick={onClick}>
            {buttonImage && <img src={buttonImage}  alt={buttonText} />}
            <span className={buttonImage && "ml-4"}>{ buttonText }</span>
        </button>
    );
};